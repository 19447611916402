import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { environment } from '@mommy/environments/environment';
import { APIResponse } from '@mommy/models/Comm.model';
import { SetExpertsToExpertState } from '@mommy/state/expert/expert.actions';
import { SetHospitalsToHospitalState } from '@mommy/state/hospital/hospital.actions';
import { SetPostsToPostState } from '@mommy/state/post/post.action';
import { SetVotesToVoteState } from '@mommy/state/vote/vote.actions';
import { Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { AppSettings } from 'app/app.settings';
import { take } from 'rxjs/operators';
import { Api } from '../api';
import { CacheService } from '../cache.service';
import { StorageService } from '../storage.service';
import { UtilService } from '../util.service';
import { QUERY_MULTIPLE_LIST } from './others.graphql';
const fs = require('fs');

@Injectable({
  providedIn: 'root',
})
export class OthersService {
  constructor(
    private api: Api,
    private apollo: Apollo,
    private util: UtilService,
    private cacheService: CacheService,
    private store: Store,
    private storageService: StorageService,
    public loadingCtrl: LoadingController
  ) {
    console.log('Hello OthersService');
  }

  getTrashObjects(): Promise<any> {
    const endpoint = `v1/codes/reference-codes/trash-objects`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = (await this.api
          .get(endpoint, null)
          .toPromise()) as any;
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        if (error.status !== 304) {
          console.error('getTrashObjects error', error);
        }
        reject(error);
      }
    });
  }

  getTrashObjectsAtServer(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        // 先從檔案讀取, 若無, 則呼叫 api 取得 並儲存到檔案
        const filename = 'cache-trash-objects.json';
        const filepath = `./${filename}`;

        console.log('@Server: getTrashObjectsAtServer filepath', filepath);
        fs.readFile(filepath, 'utf8', async (err, data) => {
          if (err) {
            console.error('@Server: getTrashObjectsAtServer error', err);
            reject(err);
          }

          if (data) {
            const data_obj = JSON.parse(data);
            console.log(
              '@Server: getTrashObjectsAtServer got cache data, rows: ',
              data_obj?.length
            );
            resolve(data_obj);
          } else {
            console.log(
              '@Server: getTrashObjectsAtServer got no cache, call api to get data'
            );
            const new_data = await this.getTrashObjects();
            fs.writeFile(filepath, JSON.stringify(new_data), (err) => {
              if (err) {
                console.error('@Server: getTrashObjectsAtServer error', err);
                reject(err);
              }

              console.log(
                '@Server: getTrashObjectsAtServer save cache success'
              );
              resolve(new_data);
            });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // 百科查詢關鍵字紀錄
  pediaKeywords(keyword): Promise<any> {
    const endpoint = `v1/member/action-log/top-encyclopedia-keywords`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.meta = keyword;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('pediaKeywords error', error);
        reject(error);
      }
    });
  }

  // 能不能吃查詢關鍵字紀錄
  canNotEatKeywords(keyword): Promise<any> {
    const endpoint = `v1/member/action-log/top-can-or-not-eat-keywords`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.meta = keyword;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('canNotEatKeywords error', error);
        reject(error);
      }
    });
  }

  // EC商品查詢關鍵字紀錄
  ECSearchKeywords(keyword): Promise<any> {
    const endpoint = `v1/member/action-log/ec-commodity-keywords`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.meta = keyword;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('canNotEatKeywords error', error);
        reject(error);
      }
    });
  }

  // 文章點擊紀錄
  postClickLog(post_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/post-click`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.object_id = post_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('postClickLog error', error);
        reject(error);
      }
    });
  }

  // 文章分享紀錄
  postShareLog(post_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/post-share`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.object_id = post_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('postShareLog error', error);
        reject(error);
      }
    });
  }

  // 專家點擊紀錄
  expertClickLog(expert_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/expert-click`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.object_id = expert_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('expertClickLog error', error);
        reject(error);
      }
    });
  }

  // 醫院點擊紀錄
  hospitalClickLog(hospital_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/hospital-click`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.object_id = hospital_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('hospitalClickLog error', error);
        reject(error);
      }
    });
  }

  // 醫院ELINE點擊紀錄
  hospitalElineClickLog(hospital_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/hospital-eline-click`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.object_id = hospital_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('hospitalElineClickLog error', error);
        reject(error);
      }
    });
  }

  // pgc文章檢舉紀錄
  pgcPostReportLog(post_id: number, reason: string): Promise<any> {
    const endpoint = `v1/pgc-post-report`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.post_id = post_id;
        params.reason = reason;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('pgcPostReportLog error', error);
        reject(error);
      }
    });
  }

  // pgc文章評論檢舉紀錄
  pgcPostCommentReportLog(
    post_comment_id: number,
    reason: string
  ): Promise<any> {
    const endpoint = `v1/pgc-post-comment-report`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.post_comment_id = post_comment_id;
        params.reason = reason;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('pgcPostCommentReportLog error', error);
        reject(error);
      }
    });
  }

  // 投票評論檢舉紀錄
  voteCommentReportLog(vote_comment_id: number, reason: string): Promise<any> {
    const endpoint = `v1/pgc-vote-comment-report`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.vote_comment_id = vote_comment_id;
        params.reason = reason;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('voteCommentReportLog error', error);
        reject(error);
      }
    });
  }

  // 加入購物車的紀錄
  addCartLog(commodity_id: number): Promise<any> {
    const endpoint = `v1/member/action-log/shipping-cart`;

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.item_id = commodity_id;

        const result: APIResponse = await (<any>(
          this.api.post(endpoint, params).toPromise()
        ));
        console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        console.error('addCartLog error', error);
        reject(error);
      }
    });
  }

  // [實驗]
  // 一次取得 graphql 資料(文章,專家,醫院,投票)
  getGraphQLData(atServer?: boolean) {
    console.log('getGraphQLData');
    return new Promise(async (resolve, reject) => {
      let expert_cursor;
      let hospital_cursor;
      let post_cursor;
      let vote_cursor;

      // 判斷是否有 app升版, check post_list_version
      let post_list_version = await this.storageService.get(
        'post_list_version'
      );
      console.log('post_list_version', post_list_version);

      // 如果是在server端(SSR), 則 full refresh
      if (atServer) {
        expert_cursor = 0;
        hospital_cursor = 0;
        post_cursor = 0;
        vote_cursor = 0;
      } else {
        if (AppSettings.APP_VERSION_CODE !== post_list_version) {
          console.log('post_list_version 不同, 需要重新取得資料');
          expert_cursor = 0;
          hospital_cursor = 0;
          post_cursor = 0;
          vote_cursor = 0;
        } else {
          expert_cursor = await this.cacheService.getDataCursor2(
            AppSettings.CACHE_KEY_EXPERT_LIST
          );
          console.warn('experts cursor', expert_cursor);
          expert_cursor = expert_cursor ? expert_cursor : 0;

          hospital_cursor = await this.cacheService.getDataCursor2(
            AppSettings.CACHE_KEY_HOSPITAL_LIST
          );
          console.warn('hospital cursor', hospital_cursor);
          hospital_cursor = hospital_cursor ? hospital_cursor : 0;

          post_cursor = await this.cacheService.getDataCursor2(
            AppSettings.CACHE_KEY_POST_LIST
          );
          console.warn('post cursor', post_cursor);
          post_cursor = post_cursor ? post_cursor : 0;

          vote_cursor = await this.cacheService.getDataCursor2(
            AppSettings.CACHE_KEY_VOTE_LIST
          );
          console.warn('vote cursor', vote_cursor);
          vote_cursor = vote_cursor ? vote_cursor : 0;
        }
      }

      console.log('all cursor', {
        expert_cursor,
        hospital_cursor,
        post_cursor,
        vote_cursor,
      });

      let loader;

      if (!atServer) {
        if (post_cursor === 0) {
          loader = await this.loadingCtrl.create({
            message: '載入中...',
          });
          await loader.present();
        }
      }

      this.apollo
        .watchQuery<{
          getExpertList: any;
          getHospitalList: any;
          getPostList: any;
          getVoteList: any;
        }>({
          query: QUERY_MULTIPLE_LIST,
          fetchPolicy: 'network-only',
          variables: {
            expert_updated_at: expert_cursor, //最近一筆的timestamp
            hospital_updated_at: hospital_cursor, //最近一筆的timestamp
            post_updated_at: post_cursor, //最近一筆的timestamp
            vote_updated_at: vote_cursor, //最近一筆的timestamp
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('QUERY_MULTIPLE_LIST data', data);
            console.log('QUERY_MULTIPLE_LIST data', data.getExpertList);
            console.log('QUERY_MULTIPLE_LIST data', data.getHospitalList);
            console.log('QUERY_MULTIPLE_LIST data', data.getPostList);
            console.log('QUERY_MULTIPLE_LIST data', data.getVoteList);

            if (!atServer) {
              if (post_cursor === 0) {
                loader.dismiss();
              }
            }

            if (data) {
              loading = false; //如果有data,表示有cache
              if (data?.getExpertList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getExpertList,
                    AppSettings.CACHE_KEY_EXPERT_LIST,
                    'expert_id'
                  );
                  // resolve(new_data);
                  this.store.dispatch(new SetExpertsToExpertState(new_data));
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  // reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                // reject('no new data need to merge');
              }

              if (data?.getHospitalList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getHospitalList,
                    AppSettings.CACHE_KEY_HOSPITAL_LIST,
                    'hospital_id'
                  );
                  // resolve(new_data);
                  this.store.dispatch(
                    new SetHospitalsToHospitalState(new_data)
                  );
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  // reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                // reject('no new data need to merge');
              }

              if (data?.getPostList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getPostList,
                    AppSettings.CACHE_KEY_POST_LIST,
                    'post_id'
                  );
                  // resolve(new_data);
                  this.store.dispatch(new SetPostsToPostState(new_data));
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  // reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                // reject('no new data need to merge');
              }

              if (data?.getVoteList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getVoteList,
                    AppSettings.CACHE_KEY_VOTE_LIST,
                    'vote_id'
                  );
                  // resolve(new_data);
                  this.store.dispatch(new SetVotesToVoteState(new_data));
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  // reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                // reject('no new data need to merge');
              }

              if (!atServer) {
                resolve(true);
              } else {
                resolve(data);
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            if (!atServer) {
              if (post_cursor === 0) {
                loader.dismiss();
              }
            }

            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  getGraphQLDataAtServer() {
    return new Promise(async (resolve, reject) => {
      try {
        // 先從檔案讀取, 若無, 則呼叫 api 取得 並儲存到檔案
        const filename = 'cache-getGraphQLData.json';
        const filepath = `./${filename}`;

        console.log('@Server: getGraphQLDataAtServer filepath', filepath);
        fs.readFile(filepath, 'utf8', async (err, data) => {
          if (err) {
            console.error('@Server: getGraphQLDataAtServer error', err);
            reject(err);
          }

          if (data) {
            const data_obj = JSON.parse(data);
            console.log(
              '@Server: getGraphQLDataAtServer got cache data, rows: ',
              data_obj?.length
            );

            // set 到 ngxs-store
            // expert
            if (data_obj?.getExpertList.length > 0) {
              try {
                this.store.dispatch(
                  new SetExpertsToExpertState(data_obj?.getExpertList)
                );
                console.log('@Server: SetExpertsToExpertState success');
              } catch (error) {
                console.error('@Server: SetExpertsToExpertState error', error);
              }
            }

            // hospital
            if (data_obj?.getHospitalList.length > 0) {
              try {
                this.store.dispatch(
                  new SetHospitalsToHospitalState(data_obj?.getHospitalList)
                );
                console.log('@Server: SetHospitalsToHospitalState success');
              } catch (error) {
                console.error(
                  '@Server: SetHospitalsToHospitalState error',
                  error
                );
              }
            }

            // post
            if (data_obj?.getPostList.length > 0) {
              try {
                this.store.dispatch(
                  new SetPostsToPostState(data_obj?.getPostList)
                );
                console.log('@Server: SetPostsToPostState success');
              } catch (error) {
                console.error('@Server: SetPostsToPostState error', error);
              }
            }

            // vote
            if (data_obj?.getVoteList.length > 0) {
              try {
                this.store.dispatch(
                  new SetVotesToVoteState(data_obj?.getVoteList)
                );
                console.log('@Server: SetVotesToVoteState success');
              } catch (error) {
                console.error('@Server: SetVotesToVoteState error', error);
              }
            }

            resolve(true);
          } else {
            console.log(
              '@Server: getGraphQLDataAtServer got no cache, call api to get data'
            );
            const new_data = await this.getGraphQLData(true);
            await this.generateSitemap(new_data);

            fs.writeFile(filepath, JSON.stringify(new_data), (err) => {
              if (err) {
                console.error('@Server: getGraphQLDataAtServer error', err);
                reject(err);
              }

              console.log('@Server: getGraphQLDataAtServer save cache success');
              resolve(new_data);
            });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  generateSitemap(data) {
    console.log('generateSitemap');

    return new Promise(async (resolve, reject) => {
      try {
        // 依序讀取 expert, hospital, post, 組合出 每一筆的 url
        // export: https://app.mommycareyou.com/expert/1
        // hospital: https://app.mommycareyou.com/hospital/1
        // post, 依照 post_type 分類, 並組合出 url
        // pgc-post: https://app.mommycareyou.com/pgc-post/1
        // canieat: https://app.mommycareyou.com/canieat-post/1
        // pedia: https://app.mommycareyou.com/pedia-post/1
        // vedio: https://app.mommycareyou.com/vedio-post/1

        const filename = 'sitemap.txt';
        const filepath = `./${filename}`;
        let output = '';

        // 1. expert
        let expert_list = data?.getExpertList;
        if (expert_list?.length > 0) {
          expert_list.forEach((expert) => {
            output += `${environment.BASE_WEBSITE_URL}/expert/${expert.expert_id}\n`;
          });
        }

        // 2. hospital
        let hospital_list = data?.getHospitalList;
        if (hospital_list?.length > 0) {
          hospital_list.forEach((hospital) => {
            output += `${environment.BASE_WEBSITE_URL}/hospital/${hospital.hospital_id}\n`;
          });
        }

        // 3. post
        let post_list = data?.getPostList;
        if (post_list?.length > 0) {
          post_list.forEach((post) => {
            if (post.post_type === 'pgc專家') {
              output += `${environment.BASE_WEBSITE_URL}/pgc-post/${post.post_id}\n`;
            } else if (post.post_type === '能不能吃') {
              output += `${environment.BASE_WEBSITE_URL}/canieat-post/${post.post_id}\n`;
            } else if (post.post_type === 'pedia百科') {
              output += `${environment.BASE_WEBSITE_URL}/pedia-post/${post.post_id}\n`;
            } else if (post.post_type === 'video視頻') {
              output += `${environment.BASE_WEBSITE_URL}/vedio-post/${post.post_id}\n`;
            }
          });
        }

        // 寫入檔案
        fs.writeFile(filepath, output, (err) => {
          if (err) {
            console.error('@Server: generateSitemap error', err);
            reject(err);
          }

          console.log('@Server: generateSitemap save cache success');
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
