// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  WWW_VERSION: '1.0.0',

  // BASE_URL: '', // 使用 angular proxy
  // BASE_URL: 'http://localhost:3000',
  // GRAPHQL_END_POINT: 'http://localhost:3000/v1/graphql',

  BASE_URL: 'https://api.dev.mommycareyou.com',
  BASE_WEBSITE_URL: 'https://app.dev.mommycareyou.com',
  GRAPHQL_END_POINT: 'https://api.dev.mommycareyou.com/v1/graphql',

  // BASE_URL: 'https://3082-124-218-35-36.ngrok.io',
  // GRAPHQL_END_POINT: 'https://3082-124-218-35-36.ngrok.io/v1/graphql',

  // default cursor: 當讀取不到 cursor 資料時, 給予default value days(目前設定 0 表示全部讀取, 設定 -365 表示讀取一年內的資料)
  // 未定應時, cursor為0
  DEFAULT_CURSOR: {
    Post: 0,
    Expert: 0,
    Hospital: 0,
  },

  // 推薦碼有效天數設定
  REFERRAL_CODE_EXPIRATION_DAYS: 3,

  // 是否啟用 Web_App, build APP時 手動設定為 true
  // v3.1 之後版本都要設定為 true, 未來再移除此設定
  WEB_APP_MODE: true,

  // 是否為網頁版(將隱藏孕關懷、待產清單、餵養紀錄、生長紀錄)
  WEB_FLAG: true,

  // LINE設定  *** callback url 不可以用 hash # ***
  line_client_id: '1657424140',
  // line_callback_login_url: 'http://127.0.0.1:8300/login',
  // line_callback_register_url: 'http://127.0.0.1:8300/register',
  line_callback_login_url: 'https://app.dev.mommycareyou.com/login',
  line_callback_register_url: 'https://app.dev.mommycareyou.com/register',
  line_scope: 'profile%20openid%20email',
  line_client_secret: '36582a80f8cd37d61b165bc40a2f2d56',

  // Peter's LINE設定(PeterLai商號測試)
  // line_client_id: '1657313860',
  // line_callback_url: 'http://localhost:8300/login',
  // line_scope: 'profile%20openid%20email',
  // line_client_secret: '20d02c63719be7eda825fcee7d967e0c',

  // ****************************************************************************************
  // Eline 設定
  // ****************************************************************************************
  // API_URL: 'https://app01.mommycareyou.com/api',
  // APP_VERSION_CHECK_URL: 'https://appinfo.mommycareyou.com/app/prod/app_version_patient.json',
  // NEWS_URL: 'https://appinfo.mommycareyou.com/app/prod/news.json',
  // OM_STATUS_URL: 'https://appinfo.mommycareyou.com/app/prod/omstatus.json',
  // RESET_PASSWORD_URL: 'https://app01.mommycareyou.com/resetpassword',
  // PUBLIC_POST_HOST: 'https://app01.mommycareyou.com/post/',
  // DEBUG_FLAG: false,
  // SELF_DOMAIN_URL: ['mommycareyou.com','mommycare.cc'],  // 自己的domain list, 會夾帶 auth_token 的判斷
  // firebaseConfig: {
  //   apiKey: 'AIzaSyBf7srj3yQPXNoKIYqzaTELa6eUUP8Ofdg',
  //   authDomain: 'mommycareyouprod.firebaseapp.com',
  //   databaseURL: 'https://mommycareyouprod-default-rtdb.asia-southeast1.firebasedatabase.app',
  //   projectId: 'mommycareyouprod',
  //   storageBucket: 'mommycareyouprod.appspot.com',
  //   messagingSenderId: '58273204584',
  //   appId: '1:58273204584:web:7bb428e8e333ad0132aedd',
  //   measurementId: 'G-9FB6M1465E',
  // },
  // DEBUG_API_URL: 'https://app01test.mommycareyou.com/api',
  // WP_API_URL: 'https://www.mommycare.cc/wp-json',
  // DEFAULT_TEAM_ID: 4, // 預設要加入的醫療團隊id (ex: 孕媽咪小幫手)

  // API_URL: 'https://app01test.mommycareyou.com/api',
  API_URL: 'https://mommy1.dev.mommycareyou.com/api',
  APP_VERSION_CHECK_URL:
    'https://appinfo.mommycareyou.com/app/test/app_version_patient.json',
  NEWS_URL: 'https://appinfo.mommycareyou.com/app/test/news.json',
  OM_STATUS_URL: 'https://appinfo.mommycareyou.com/app/test/omstatus.json',
  RESET_PASSWORD_URL: 'https://mommy1.dev.mommycareyou.com/resetpassword',
  PUBLIC_POST_HOST: 'https://mommy1.dev.mommycareyou.com/post/',
  DEBUG_FLAG: true,
  SELF_DOMAIN_URL: ['mommycareyou.com', 'mommy-care.knockers.tw'], // 自己的domain list, 會夾帶 auth_token 的判斷
  MALLDJ_URL: ['www.malldj.com'], //異網url
  firebaseConfig: {
    apiKey: 'AIzaSyBN7IksWy6gA6eK_dyRCQUdczRXqhYAbR8',
    authDomain: 'mommycaretest.firebaseapp.com',
    databaseURL: 'https://mommycaretest-default-rtdb.firebaseio.com',
    projectId: 'mommycaretest',
    storageBucket: 'mommycaretest.appspot.com',
    messagingSenderId: '590302197367',
    appId: '1:590302197367:web:7ff72628e8916078423872',
    measurementId: 'G-X9PZBMJDTC',
  },
  DEBUG_API_URL: 'https://mommy1.dev.mommycareyou.com.tw/api',
  WP_API_URL: 'https://mommy-care.knockers.tw/wp-json',
  DEFAULT_TEAM_ID: 4, // 預設要加入的醫療團隊id (ex: 孕媽咪小幫手),

  // 會員邀請推薦註冊的 URL
  REFERRAL_REGISTER_URL: 'https://app.dev.mommycareyou.com/register',

  // 分享 content(eg pgc,video,ec-product) 的 domain url
  SHARELINK_DOMAIN: 'https://app.dev.mommycareyou.com/seo/',

  // app deeplink domain
  APP_DEEPLINK_DOMAIN: 'https://app.dev.mommycareyou.com',

  // ＡccessToken 預設為1年, 若效期小於以下參數, 則將進行 refresh
  ACCESS_TOKEN_EXPIRED_IN_DAYS: 90,

  // EC 相關
  EC_API_URL: 'https://api.ec.dev.mommycareyou.com',
  EC_IMAGE_CDN_URL: 'https://api.ec.dev.mommycareyou.com/cdn/',
  EC_COMMODITY_PAGE_SIZE: 50, // EC商品list 每頁筆數
  EC_SEARCH_COMMODITY_PAGE_SIZE: 50, // EC查詢商品list 每頁筆數
  EC_BILLLIST_PAGE_SIZE: 5, // 會員訂單list 每頁筆數
  EC_COIN_HIST_PAGE_SIZE: 10, // 會員咪幣紀錄 每頁筆數
  EC_PAYMENT_URL:
    'https://api.ec.dev.mommycareyou.com/Portal/Commerce/ThirdPartyPayment',
  EC_PAYMENT_URL_WEB:
    'https://api.ec.dev.mommycareyou.com/Portal/Commerce/ThirdPartyPaymentWeb',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
