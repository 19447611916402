import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { StorageService } from '@mommy/services/storage.service';
import { TaxonomyService } from '@mommy/services/taxonomy/taxonomy.service';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { LoadCacheTaxonomy, RefreshTaxonomy } from './taxonomy.actions';

export interface TaxonomyStateModel {
  loading: boolean;
  taxonomies: CodeTaxonomyInfo[];
  hasCache: boolean;
}

const defaultTaxonomyState = (): TaxonomyStateModel => {
  return {
    loading: false,
    taxonomies: [],
    hasCache: false,
  };
};

@State<TaxonomyStateModel>({
  name: 'TaxonomyState',
  defaults: defaultTaxonomyState(),
})
@Injectable()
export class TaxonomyState implements NgxsAfterBootstrap {
  private _isServer: boolean;

  constructor(
    private storage: StorageService,
    private taxonomySvc: TaxonomyService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  async ngxsAfterBootstrap(ctx: StateContext<TaxonomyStateModel | null>) {
    console.log('[TaxonomyState] ngxsAfterBootstrap');

    // const getTaxonomyFromServer = async () => {
    //   // try read data from server
    //   console.log('getTaxonomyFromServer');
    //   try {
    //     let taxonomies;
    //     if (this._isServer) {
    //       taxonomies = await this.taxonomySvc.getAllTaxonomyAtServer();
    //     } else {
    //       taxonomies = await this.taxonomySvc.getAllTaxonomy();
    //     }
    //     console.log('taxonomies-fromServer', taxonomies);
    //     await this.storage.set('taxonomies', taxonomies);
    //     await ctx.dispatch(new LoadCacheTaxonomy()).toPromise();
    //     console.log('LoadCacheTaxonomy success');
    //   } catch (error2) {
    //     console.warn('getAllTaxonomy error', error2);
    //   }
    // };

    try {
      await ctx.dispatch(new LoadCacheTaxonomy()).toPromise();
      console.log('load local cache taxonomy success');
      // 再呼叫 getAllTaxonomy 來更新 local cache
      this.getTaxonomyFromServer(ctx);
    } catch (error) {
      console.warn('LoadCacheTaxonomy error', error);
      // 如果沒有 cache, 就去 server 取
      this.getTaxonomyFromServer(ctx, true);
    }
  }

  // 建立 dynamic selector, 傳入 type name(ex: 科別專長, 能不能吃分類..) 回傳對應類別的 taxonomy
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static taxonomyType(type: string) {
    return createSelector([TaxonomyState], (state: TaxonomyStateModel) => {
      return state.taxonomies.filter(
        (item) => item.taxonomy_type.indexOf(type) > -1
      );
    });
  }

  static taxonomyById(taxonomy_id: number) {
    return createSelector([TaxonomyState], (state: TaxonomyStateModel) => {
      return state.taxonomies.filter(
        (item) => item.taxonomy_id === taxonomy_id
      );
    });
  }

  @Action(LoadCacheTaxonomy)
  async loadCacheTaxonomy(ctx: StateContext<TaxonomyStateModel>) {
    const state = ctx.getState();
    const _taxonomy: any = await this.storage.get('taxonomies');
    console.log('loadCacheTaxonomy-state', _taxonomy?.length);

    if (_taxonomy) {
      ctx.patchState({
        loading: false,
        taxonomies: _taxonomy,
        hasCache: true,
      });
    } else {
      console.warn('loadCacheTaxonomy: no cache');
      throw new Error('no cache');
    }
  }

  @Action(RefreshTaxonomy)
  async refreshTaxonomy(ctx: StateContext<TaxonomyStateModel>, { force }) {
    console.log('[Action] refreshTaxonomy', force);
    await this.getTaxonomyFromServer(ctx, force);
  }

  private async getTaxonomyFromServer(
    ctx: StateContext<TaxonomyStateModel>,
    force?: boolean
  ) {
    // try read data from server
    console.log('getTaxonomyFromServer');
    try {
      let taxonomies;
      if (this._isServer) {
        taxonomies = await this.taxonomySvc.getAllTaxonomyAtServer();
      } else {
        taxonomies = await this.taxonomySvc.getAllTaxonomy(force);
      }
      console.log('taxonomies-fromServer', taxonomies);
      await this.storage.set('taxonomies', taxonomies);
      await ctx.dispatch(new LoadCacheTaxonomy()).toPromise();
      console.log('LoadCacheTaxonomy success');
    } catch (error2) {
      console.warn('getAllTaxonomy error', error2);
    }
  }
}
