import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { UserState } from '@mommy/state/user/user.state';
import { Select } from '@ngxs/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-expert-list-item',
  templateUrl: './expert-list-item.component.html',
  styleUrls: ['./expert-list-item.component.scss'],
})
export class ExpertListItemComponent implements OnInit, OnDestroy {
  @Select(UserState.mommy_user) mommy_user$;
  private ngUnsubscribe = new Subject();

  @Input() showWatch: boolean;
  @Output() expertOnClick = new EventEmitter<ExpertInfo>();
  @Output() expertAddWatchOnClick = new EventEmitter<ExpertInfo>();
  @Output() expertRemoveWatchOnClick = new EventEmitter<ExpertInfo>();

  _expert: ExpertInfo;
  _expert_tag: string; // 副標題
  _presentation: 'section-hospital' | 'section' = 'section-hospital';
  _job_title: string;

  _has_watch = false;

  constructor() {}

  ngOnInit() {
    // user$, 訂閱 user 資料, 以判斷是否有加入關注
    this.mommy_user$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mommy_user) => {
        console.log('mommy_user$', mommy_user);

        if (mommy_user) {
          const expert = _.find(mommy_user.expert_watchs, {
            expert_id: this._expert?.expert_id,
          });
          if (expert) {
            this._has_watch = true;
          } else {
            this._has_watch = false;
          }
        } else {
          this._has_watch = false;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  @Input() set presentation(value: 'section-hospital' | 'section') {
    this._presentation = value;
  }

  get expert(): ExpertInfo {
    return this._expert;
  }

  @Input() set expert(value: ExpertInfo) {
    console.log('expert', value);
    this._expert = value;

    let _section = '';
    if (value.subject_classes?.length > 0) {
      _section = value.subject_classes[0].taxonomy_name;
    }

    if (this._presentation === 'section-hospital') {
      if (value.outpatients?.length > 0) {
        this._expert_tag = `${_section}-${value.outpatients[0]?.outpatient_hospital?.hospital_name}`;
      } else {
        this._expert_tag = `${_section}`;
      }
    } else if (this._presentation === 'section') {
      this._expert_tag = `${_section}`;
      if (value.job_title) {
        this._job_title = `- ${value.job_title}`;
      }
    }
  }

  expertClick() {
    this.expertOnClick.emit(this._expert);
  }

  addWatchClick(event) {
    event.stopPropagation();
    this.expertAddWatchOnClick.emit(this._expert);
  }

  removeWatchClick(event) {
    event.stopPropagation();
    this.expertRemoveWatchOnClick.emit(this._expert);
  }
}
