import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID,
  ViewContainerRef,
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[inView]' })
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class InView implements AfterViewInit {
  @Output() inViewEvent = new EventEmitter();
  alreadyRendered: boolean; // cheking if visible already
  private _isServer: boolean;

  constructor(
    private vcRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  ngAfterViewInit() {
    // 在 server 端不做任何事情
    if (this._isServer) {
      return;
    }

    const commentEl = this.vcRef.element.nativeElement; // template
    const elToObserve = commentEl.parentElement;
    // this.setMinWidthHeight(elToObserve);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // this.renderContents(entry.isIntersecting);
          // console.log('entry', entry);
          this.inViewEvent.emit(entry.isIntersecting);
        });
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    );
    observer.observe(elToObserve);
  }

  // renderContents(isInView) {
  //   if (isInView && !this.alreadyRendered) {
  //     this.vcRef.clear();
  //     this.vcRef.createEmbeddedView(this.tplRef);
  //     this.alreadyRendered = true;
  //   }
  // }

  // setMinWidthHeight(el) { // prevent issue being visible all together
  //   const style = window.getComputedStyle(el);
  //   const [width, height] = [parseInt(style.width), parseInt(style.height)];
  //   !width && (el.style.minWidth = '40px');
  //   !height && (el.style.minHeight = '40px');
  // }
}
