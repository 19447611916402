import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MyInfo } from '@mommy/models/MyInfo.model';
import { TaskInfo } from '@mommy/models/TaskInfo.model';
import { MemberTaskService } from '@mommy/services/member/member-task.service';
import { StorageService } from '@mommy/services/storage.service';
import { TaskService } from '@mommy/services/task/task.service';
import { UtilService } from '@mommy/services/util.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { UserState } from '../user/user.state';
import {
  InitLocalCacheTasks,
  LoadCacheTasks,
  RefreshTasks,
  RetriveAward,
} from './task.actions';

export interface TaskStateModel {
  loading: boolean;
  tasks: TaskInfo[];
  hasCache: boolean;
}

const defaultTaskState = (): TaskStateModel => {
  return {
    loading: false,
    tasks: [],
    hasCache: false,
  };
};

@State<TaskStateModel>({
  name: 'TaskState',
  defaults: defaultTaskState(),
})
@Injectable()
export class TaskState {
  private _isServer: boolean;

  constructor(
    private storage: StorageService,
    private taskService: TaskService,
    public util: UtilService,
    private memberTaskService: MemberTaskService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  @Selector()
  static tasks(state: TaskStateModel) {
    return state.tasks;
  }

  // 取得簽到任務
  @Selector([TaskState.tasks])
  static getSigninTask(tasks: TaskInfo[]) {
    return _.find(tasks, (task) => task.task_code === 'TASK_SIGNIN');
  }

  // 依據使用者的level,孕期狀態 取得任務列表
  // 再依據使用者的任務紀錄, map new tasks data
  // filter:status is publish, 排除 TASK_SIGNIN , member level, order by task_id asc
  @Selector([TaskState.tasks, UserState.mommy_user])
  static tasksByUser(tasks: TaskInfo[], mommy_user: MyInfo) {
    console.log('tasksByUser tasks', tasks);
    console.log('tasksByUser mommy_user', mommy_user);

    const _member_tasks = _.map(
      _.cloneDeep(mommy_user?.member_tasks),
      (elem) => {
        if (elem.reward_at) {
          elem.member_task_status = 'REWARDED';
        } else if (elem.finished_at) {
          elem.member_task_status = 'DONE';
        } else if (elem.memo) {
          elem.member_task_status = 'WIP';
        } else {
          elem.member_task_status = 'NEW';
        }
        return elem;
      }
    );

    return _.chain(tasks)
      .filter(
        (task: TaskInfo) =>
          task.task_status === 'publish' &&
          (task.member_level === 'BASIC' ||
            task.member_level === mommy_user?.basic_info.member_level) &&
          task.task_code !== 'TASK_SIGNIN'
      )
      .filter((task: TaskInfo) => {
        if (mommy_user?.basic_info.pregnancy_tag_taxonomy_id) {
          // 代表為孕婦, 可以執行孕婦的任務
          return true;
        } else {
          // 代表不是孕婦, 不可以執行孕婦的任務
          const pregnancy_tasks = ['TASK_QUEEN_VIDEO', 'TASK_QUEEN_VOTE'];
          if (pregnancy_tasks.includes(task.task_code)) {
            return false; // 過濾掉陣列內的任務
          } else {
            return true; // 可以執行非孕婦的任務
          }
        }
      })
      .map((task: TaskInfo) => {
        const new_task = _.cloneDeep(task);
        new_task.member_task_info = _.filter(
          _member_tasks,
          (member_task_info: any) =>
            member_task_info.task_code === task.task_code
        );

        // 判斷 member_task_css_completed
        // 預設抓最後一個陣列元素, 代表最新的任務紀錄(ex: 邀請任務可以多次完成)
        if (new_task.member_task_info.length > 0) {
          const member_task_info =
            new_task.member_task_info[new_task.member_task_info.length - 1];
          if (member_task_info.member_task_status === 'REWARDED') {
            new_task.member_task_css_completed = true;
          } else if (member_task_info.member_task_status === 'DONE') {
            new_task.member_task_css_completed = false;
          } else if (member_task_info.member_task_status === 'WIP') {
            new_task.member_task_css_completed = false;
          } else {
            new_task.member_task_css_completed = false;
          }
          new_task.member_task_status = member_task_info.member_task_status;
        } else {
          new_task.member_task_css_completed = false;
          new_task.member_task_status = 'NEW';
        }
        return new_task;
      })
      .orderBy('task_id', 'asc')
      .value();
  }

  //#region ========== Actions ==========
  @Action(InitLocalCacheTasks)
  async initLocalCacheTasks(ctx: StateContext<TaskStateModel>) {
    console.log('[Action] initLocalCacheTasks');

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheTasks()).toPromise();
      console.log('load local cache tasks success');
      // 再呼叫 getTasksFromServer 來更新 local cache
      // this.getTasksFromServer(ctx);
      await ctx.dispatch(new RefreshTasks()).toPromise();
    } catch (error) {
      console.warn('LoadCacheTasks error', error);
      // 如果沒有 cache, 就去 server 取
      // this.getTasksFromServer(ctx);
      await ctx.dispatch(new RefreshTasks(true)).toPromise();
    }
  }

  @Action(LoadCacheTasks)
  async loadCacheTasks(ctx: StateContext<TaskStateModel>) {
    console.log('[Action] loadCacheTasks');

    const state = ctx.getState();
    const _tasks: any = await this.storage.get('tasks');

    if (_tasks) {
      ctx.patchState({
        loading: false,
        tasks: _tasks,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshTasks)
  async refreshTasks(ctx: StateContext<TaskStateModel>, { force }) {
    console.log('[Action] RefreshTasks', force);
    this.getTasksFromServer(ctx, force);
  }

  // 領取任務獎勵
  @Action(RetriveAward)
  async retriveAward(
    ctx: StateContext<TaskStateModel>,
    { member_task_id }: RetriveAward
  ) {
    console.log('[Action] RetriveAward');

    try {
      const result = await this.memberTaskService.retrive_award(member_task_id);
      console.log('retrive_award result', result);
    } catch (error) {
      console.error('RetriveAward error', error);
      throw error;
    }
  }
  //#endregion

  private async getTasksFromServer(
    ctx: StateContext<TaskStateModel>,
    force?: boolean
  ) {
    // try read data from server
    console.log('getTasksFromServer');
    try {
      let tasks;
      if (this._isServer) {
        tasks = await this.taskService.getAllTasksAtServer();
      } else {
        tasks = await this.taskService.getAllTasks(force);
      }

      console.log('tasks', tasks);
      await this.storage.set('tasks', tasks);

      ctx.patchState({
        loading: false,
        tasks: tasks,
        hasCache: true,
      });
      console.log('load local cache hospitals success');
    } catch (error2) {
      console.warn('getHospitalList error', error2);
    }
  }
}
