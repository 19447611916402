export class LoadCacheTaxonomy {
  static readonly type = '[Taxonomy] LoadCache';
}

export class SaveCacheTaxonomy {
  static readonly type = '[Taxonomy] SaveCache';
  constructor(public payload: any) {}
}

export class RefreshTaxonomy {
  static readonly type = '[Taxonomy] RefreshTaxonomy';
  constructor(public force?: boolean) {}
}
