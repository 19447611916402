import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  InitGraphqlData,
  InitLocalCacheTrashObjects,
} from '@mommy/state/app-init/app-init.actions';
import { SetAPPUIMode } from '@mommy/state/app-ui/app-ui.actions';
import { LoadCacheAuth } from '@mommy/state/auth/auth.actions';
import { AuthState } from '@mommy/state/auth/auth.state';
import { InitLocalCacheBabyDiaryPost } from '@mommy/state/baby-diary-post/baby-diary-post.actions';
import {
  GetECFavoriteCommodityList,
  InitLocalCacheECCommodityCategories,
} from '@mommy/state/ec/ec-commodity/ec-commodity.actions';
import { InitLocalCacheElineHospitals } from '@mommy/state/eline-hospital/eline-hospital.actions';
import {
  LoadCacheExperts,
  RefreshExperts,
} from '@mommy/state/expert/expert.actions';
import { LoadCacheHospitals } from '@mommy/state/hospital/hospital.actions';
import { InitLocalCacheLookup } from '@mommy/state/lookup/lookup.actions';
import { InitLocalCacheMaternityKit } from '@mommy/state/maternitykit/maternitykit.actions';
import {
  AddMemberPostLike,
  RemoveMemberPostLike,
} from '@mommy/state/member-post-like/member-post-like.actions';
import {
  CalculatePgcPostScore,
  LoadCachePosts,
} from '@mommy/state/post/post.action';
import { InitLocalCachePregnancyPost } from '@mommy/state/pregnancy-post/pregnancy-post.actions';
import { InitLocalCacheTasks } from '@mommy/state/task/task.actions';
import { InitLocalCacheUser } from '@mommy/state/user/user.actions';
import { UserState } from '@mommy/state/user/user.state';
import { LoadCacheVote } from '@mommy/state/vote/vote.actions';
import { InitLocalCacheZipcode } from '@mommy/state/zipcode/zipcode.actions';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import * as _ from 'lodash';
import { throttleTime } from 'rxjs/operators';
import {
  AddMemberExpertWatch,
  RemoveMemberExpertWatch,
} from './../state/member-expert-watch/member-expert-watch.actions';
import {
  AddMemberPostKeep,
  RemoveMemberPostKeep,
} from './../state/member-post-keep/member-post-keep.actions';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class StateUtilsService {
  private _isServer: boolean;

  constructor(
    private store: Store,
    private storage: StorageService,
    public platform: Platform,
    private actions$: Actions,
    @Inject(PLATFORM_ID) private platformId
  ) {
    console.log('Hello StateUtilsService');
    this._isServer = isPlatformServer(this.platformId);
  }

  async initApp() {
    try {
      const platform_ready = await this.platform.ready();
      console.log('Platform ready:', platform_ready);
      await this.storage.init();
      return true;
    } catch (error) {
      console.error('initApp', error);
      return false;
    }
  }

  // 在APP啟動後,預載入的資料處理
  async initAppState() {
    console.log('initAppState');
    await this.loadAppUIMode();
    // await this.store.dispatch(new InitLocalCacheTrashObjects()).toPromise(); // 載入及更新[已刪除物件]

    await this.store.dispatch([
      new LoadCacheExperts(),
      new LoadCacheHospitals(),
      new LoadCachePosts(),
      new LoadCacheVote(),
      new InitLocalCacheTrashObjects(), // 載入及更新[已刪除物件]
    ]);

    if (!this._isServer) {
      // 有cache先載入cache, 後在執行 LoadCacheAuth and checkToken
      await this.store.dispatch(new LoadCacheAuth()).toPromise(); // 載入及更新[登入]資料
      await this.store.dispatch(new InitLocalCacheUser()).toPromise(); // 會呼叫 RefreshMommyUser)
    }

    await this.store
      .dispatch([
        //new LoadCacheTaxonomy(), // ngxsAfterBootstrap
        // new InitLocalCacheExperts(), // 載入及更新[專家]資料
        // new InitLocalCachePosts(), // 載入及更新[文章]資料
        new InitLocalCachePregnancyPost(), // 載入及更新[孕期變化]文章
        // new InitLocalCacheHospitals(), // 載入及更新[醫院]資料
        new InitGraphqlData(), // [實驗] 一次載入及更新[GraphQL]資料 -> beta測試
      ])
      .toPromise();

    this.store.dispatch([
      //new LoadCacheTaxonomy(), // ngxsAfterBootstrap
      new InitLocalCacheLookup(), // 有包含首頁的一些設定,ex: 推薦pgc, 熱門活動, video..
      //new InitLocalCacheUser(), // 會呼叫 RefreshMommyUser
      new CalculatePgcPostScore(),
      new InitLocalCacheZipcode(),
      new InitLocalCacheMaternityKit(),
      new InitLocalCacheBabyDiaryPost(),
      // new InitLocalCacheVote(),  // --> 已經包含在 InitGraphqlData
      new InitLocalCacheElineHospitals(), // 載入eline醫療團隊資料
      new InitLocalCacheTasks(), // 載入 tasks 資料
    ]);

    if (!this._isServer) {
      this.store.dispatch([
        new InitLocalCacheECCommodityCategories(), // 載入 EC 目錄資料
        new GetECFavoriteCommodityList(), // 載入 EC 熱門活動商品
      ]);
    } else {
      this.store.dispatch([
        new InitLocalCacheECCommodityCategories(), // 載入 EC 目錄資料
      ]);
    }

    this.initActionMonitor();

    console.log('initAppState done');
  }

  async initActionMonitor() {
    // 如果有調整 關注專家的 action 後, 就要重新刷新專家資料, 要顯示關注數,like數..etc
    this.actions$
      .pipe(
        ofActionSuccessful(
          AddMemberExpertWatch,
          RemoveMemberExpertWatch,
          AddMemberPostKeep,
          RemoveMemberPostKeep,
          AddMemberPostLike,
          RemoveMemberPostLike
        )
      )
      .pipe(throttleTime(2000))
      .subscribe(async () => {
        console.log(
          'got AddMemberExpertWatch/RemoveMemberExpertWatch...etc actions successful!'
        );
        try {
          await this.store.dispatch(new RefreshExperts()).toPromise();
        } catch (error) {
          console.error('RefreshExperts error', error);
        }
      });
  }

  async loadAppUIMode() {
    const app_ui_mode = await this.storage.get('app_ui_mode');
    if (app_ui_mode) {
      if (app_ui_mode === 'baby') {
        await this.store.dispatch(new SetAPPUIMode('baby')).toPromise();
      } else {
        await this.store.dispatch(new SetAPPUIMode('pregnancy')).toPromise();
      }
    } else {
      await this.store.dispatch(new SetAPPUIMode('pregnancy')).toPromise();
    }
  }

  // TODO: 待處理
  getAuthToken() {
    return this.store.selectSnapshot(AuthState.mommy_token);
  }
  getECAccessToken() {
    return this.store.selectSnapshot(AuthState.ec_token);
  }

  isMommyAuthenticated() {
    return this.store.selectSnapshot(AuthState.isMommyAuthenticated);
  }

  isElineAuthenticated() {
    return this.store.selectSnapshot(AuthState.isElineAuthenticated);
  }

  isECAuthenticated() {
    return this.store.selectSnapshot(AuthState.isECAuthenticated);
  }

  getElineUser() {
    return this.store.selectSnapshot(UserState.eline_user);
  }

  getMommyUser() {
    return this.store.selectSnapshot(UserState.mommy_user);
  }

  // enter channel state
  getSelectedChannel() {
    const channel = this.store.selectSnapshot(
      (state) => state.ChannelState.selectedChannel
    );
    return channel;
  }

  getEnterChannelSelectedHospital() {
    const hospital = this.store.selectSnapshot(
      (state) => state.ChannelState.selectedHospital
    );
    return hospital;
  }

  getEnterChannelSelectedPatient() {
    const patient = this.store.selectSnapshot(
      (state) => state.ChannelState.selectedPatient
    );
    return patient;
  }

  getElineHospital(team_id: number) {
    const hospitals = this.store.selectSnapshot(
      (state) => state.ElineHospitalState.hospitals
    );
    return _.find(hospitals, { team_id });
  }

  getHospitalByTeamId(team_id: number) {
    const hospitals = this.store.selectSnapshot(
      (state) => state.HospitalState.hospitals
    );
    return _.find(hospitals, { hospital_id: team_id });
  }

  getChannelById(channel_id: string) {
    const channels = this.store.selectSnapshot(
      (state) => state.ChannelState.channels
    );
    return _.find(channels, { cid: channel_id });
  }
}
