import { Injectable } from '@angular/core';
import { UtilService } from '@mommy/services/util.service';
import { Apollo } from 'apollo-angular';
import { AppSettings } from 'app/app.settings';
import { take } from 'rxjs/operators';
import { CacheService } from '../cache.service';
import { QUERY_VOTE_DETAIL, QUERY_VOTE_LIST } from './vote.graphql';
const fs = require('fs');

@Injectable({ providedIn: 'root' })
export class VoteService {
  constructor(
    private apollo: Apollo,
    private util: UtilService,
    private cacheService: CacheService
  ) {}

  // 取得投票列表
  getVoteList() {
    console.log('getVoteList');
    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<any>({
          query: QUERY_VOTE_LIST,
          fetchPolicy: 'network-only',
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          async ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getVoteList.length > 0) {
                try {
                  const new_data = await this.cacheService.mergeData2(
                    data?.getVoteList,
                    AppSettings.CACHE_KEY_VOTE_LIST,
                    'vote_id'
                  );
                  resolve(new_data);
                } catch (error) {
                  console.error('cacheService merge data error', error);
                  reject(-1);
                }
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            const msg = this.util.getErrorDesc(graphQLErrors, networkError);
            this.util.showToast(msg, '', 0, true);
            reject(-1);
          }
        );
    });
  }

  getVoteListAtServer() {
    return new Promise(async (resolve, reject) => {
      try {
        // 先從檔案讀取, 若無, 則呼叫 api 取得 並儲存到檔案
        const filename = 'cache-getVoteList.json';
        const filepath = `./${filename}`;

        console.log('@Server: getVoteListAtServer filepath', filepath);
        fs.readFile(filepath, 'utf8', async (err, data) => {
          if (err) {
            console.error('@Server: getVoteListAtServer error', err);
            reject(err);
          }

          if (data) {
            const data_obj = JSON.parse(data);
            console.log(
              '@Server: getVoteListAtServer got cache data, rows: ',
              data_obj?.length
            );
            resolve(data_obj);
          } else {
            console.log(
              '@Server: getVoteListAtServer got no cache, call api to get data'
            );
            const new_data = await this.getVoteList();
            fs.writeFile(filepath, JSON.stringify(new_data), (err) => {
              if (err) {
                console.error('@Server: getVoteListAtServer error', err);
                reject(err);
              }

              console.log('@Server: getVoteListAtServer save cache success');
              resolve(new_data);
            });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // 取得投票detail資料
  getVoteDetail(vote_id: number) {
    return new Promise(async (resolve, reject) => {
      this.apollo
        .watchQuery<any>({
          query: QUERY_VOTE_DETAIL,
          fetchPolicy: 'network-only',
          variables: {
            vote_id,
          },
        })
        .valueChanges.pipe(take(1))
        .subscribe(
          ({ data, loading }) => {
            console.log('loading', loading);
            console.log('data', data);

            if (data) {
              // loading = false; //如果有data,表示有cache
              if (data?.getVote) {
                resolve(data?.getVote);
              } else {
                console.log('no new data need to merge..');
                reject('no new data need to merge');
              }
            } else {
              reject('data is null');
            }
          },
          ({ graphQLErrors, networkError }) => {
            console.log('graphQLErrors', graphQLErrors);
            console.log('networkError', networkError);
            reject(-1);
          }
        );
    });
  }
}
