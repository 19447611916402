export class InitLocalCachePregnancyPost {
  static readonly type = '[PregnancyPost] InitLocalCache';
}

export class LoadCachePregnancyPost {
  static readonly type = '[PregnancyPost] LoadCache';
}

export class RefreshPregnancyPost {
  static readonly type = '[PregnancyPost] Refresh';
  constructor(public force?: boolean) {}
}
