import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Team } from '@mommy/models/Eline.model';
import { ElineHospitalService } from '@mommy/services/eline/eline-hospital-service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import * as _ from 'lodash';
import {
  GetElineHospitalDetail,
  InitLocalCacheElineHospitals,
  LoadCacheElineHospitals,
  RefreshElineHospitals,
} from './eline-hospital.actions';

export interface ElineHospitalStateModel {
  loading: boolean;
  hospitals: Team[];
  detailHospitals: Team[];
  hasCache: boolean;
}

const defaultElineHospitalState = (): ElineHospitalStateModel => {
  return {
    loading: false,
    hospitals: [],
    detailHospitals: [],
    hasCache: false,
  };
};

@State<ElineHospitalStateModel>({
  name: 'ElineHospitalState',
  defaults: defaultElineHospitalState(),
})
@Injectable()
export class ElineHospitalState {
  private _isServer: boolean;

  constructor(
    private storage: StorageService,
    private elineHospitalService: ElineHospitalService,
    public util: UtilService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  // 建立 dynamic selector, 傳入 hospital_id, 回傳對應的 hospital
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static detailHospital(team_id: number) {
    return createSelector(
      [ElineHospitalState],
      (state: ElineHospitalStateModel) => {
        return _.find(state.detailHospitals, { team_id });
      }
    );
  }

  @Selector()
  static hospitals(state: ElineHospitalStateModel) {
    return state.hospitals;
  }

  //#region ========== Actions ==========
  @Action(InitLocalCacheElineHospitals)
  async initLocalCacheElineHospitals(
    ctx: StateContext<ElineHospitalStateModel>
  ) {
    console.log('[Action] InitLocalCacheElineHospitals');

    // const _hospitals: any = await this.storage.get(
    //   AppSettings.CACHE_KEY_HOSPITAL_LIST
    // );

    // if (_hospitals) {
    //   // do nothing
    // } else {
    //   this.getHospitalsFromServer(ctx);
    // }

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheElineHospitals()).toPromise();
      console.log('load local cache eline hospital success');
      // 再呼叫 getHospitalsFromServer 來更新 local cache
      // this.getHospitalsFromServer(ctx);
      await ctx.dispatch(new RefreshElineHospitals()).toPromise();
    } catch (error) {
      console.warn('LoadCacheElineHospitals error', error);
      // 如果沒有 cache, 就去 server 取
      // this.getHospitalsFromServer(ctx);
      await ctx.dispatch(new RefreshElineHospitals()).toPromise();
    }
  }

  @Action(LoadCacheElineHospitals)
  async loadCacheElineHospitals(ctx: StateContext<ElineHospitalStateModel>) {
    console.log('[Action] LoadCacheElineHospitals');

    const state = ctx.getState();
    const _hospitals: any = await this.storage.get('eline-hospitals');

    if (_hospitals) {
      ctx.patchState({
        loading: false,
        hospitals: _hospitals,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshElineHospitals)
  async refreshElineHospitals(ctx: StateContext<ElineHospitalStateModel>) {
    console.log('[Action] RefreshElineHospitals');
    this.getElineHospitalsFromServer(ctx);
  }

  @Action(GetElineHospitalDetail)
  async getElineHospitalDetail(
    ctx: StateContext<ElineHospitalStateModel>,
    action: GetElineHospitalDetail
  ) {
    console.log('[Action] GetElineHospitalDetail');
    const state = ctx.getState();

    // 先載入 local cache
    const _hospital: any = await this.storage.get(
      'eline-hospital-' + action.team_id
    );

    // await this.util.sleep(3000);
    const _detailHospitals = state.detailHospitals;
    const new_detailHospitals = _.cloneDeep(_detailHospitals);

    if (_hospital) {
      // 先判斷 detailHospitals 是否已經存在, 如果有就置換, 沒有就新增至 detailHospitals
      const index = _.findIndex(new_detailHospitals, {
        team_id: action.team_id,
      });
      if (index > -1) {
        new_detailHospitals.splice(index, 1, _hospital);
      } else {
        new_detailHospitals.push(_hospital);
      }

      ctx.patchState({
        detailHospitals: new_detailHospitals,
      });

      this.getElineHospitalDetailFromServer(ctx, action.team_id); // 再呼叫 getHospitalDetail 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getElineHospitalDetailFromServer(ctx, action.team_id);
    }
  }

  //#endregion

  private async getElineHospitalsFromServer(
    ctx: StateContext<ElineHospitalStateModel>
  ) {
    // try read data from server
    console.log('getElineHospitalsFromServer');
    try {
      let hospitals;
      if (this._isServer) {
        hospitals = await this.elineHospitalService.fetchHospitalsAtServer();
      } else {
        hospitals = await this.elineHospitalService.fetchHospitals();
      }

      console.log('eline-hospitals', hospitals);
      await this.storage.set('eline-hospitals', hospitals);

      ctx.patchState({
        loading: false,
        hospitals,
        hasCache: true,
      });
      console.log('load local cache hospitals success');
    } catch (error2) {
      console.warn('getHospitalList error', error2);
    }
  }

  private async getElineHospitalDetailFromServer(
    ctx: StateContext<ElineHospitalStateModel>,
    team_id: number
  ) {
    // try read data from server
    console.log('getElineHospitalDetailFromServer');
    const state = ctx.getState();

    try {
      const hospital_intro = await this.elineHospitalService.get_hospital_intro(
        team_id
      );
      const hospital = _.chain(state.hospitals)
        .find({ team_id })
        .cloneDeep()
        .value();
      hospital.intro = hospital_intro;
      console.log('eline-hospital', hospital);
      await this.storage.set('eline-hospital-' + team_id, hospital);

      const _detailHospitals = state.detailHospitals;
      const new_detailHospitals = _.cloneDeep(_detailHospitals);

      const index = _.findIndex(new_detailHospitals, { team_id });
      if (index > -1) {
        new_detailHospitals.splice(index, 1, hospital);
      } else {
        new_detailHospitals.push(hospital);
      }

      ctx.patchState({
        detailHospitals: new_detailHospitals,
      });
    } catch (error2) {
      console.warn('getElineHospitalDetailFromServer error', error2);
    }
  }
}
