import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ExpertInfo } from '@mommy/models/ExpertInfo.model';
import { HospitalInfo } from '@mommy/models/HospitalInfo.model';
import { PostInfo } from '@mommy/models/PostInfo.model';
import { CommodityInfo } from '@mommy/models/ec/CommodityInfo.model';
import { AppSettings } from 'app/app.settings';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private title: Title, private meta: Meta) {
    this.meta.addTags([
      {
        name: 'description',
        content: AppSettings.SEO_DEFAULT_DESCRIPTION,
      },
      { charset: 'UTF-8' },
    ]);

    this.title.setTitle(AppSettings.SEO_DEFAULT_TITLE);
  }

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
    } else {
      this.title.setTitle(AppSettings.SEO_DEFAULT_TITLE);
    }
  }

  updateDescription(description: string) {
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    } else {
      this.meta.updateTag({
        name: 'description',
        content: AppSettings.SEO_DEFAULT_DESCRIPTION,
      });
    }
  }

  setPostMeta(post: PostInfo) {
    if (post) {
      this.updateTitle(post.post_title + AppSettings.SEO_DEFAULT_SUFFIX);
      this.updateDescription(post.post_excerpt);
    }
  }

  setHospitalMeta(hospital: HospitalInfo) {
    if (hospital) {
      this.updateTitle(hospital.hospital_name + AppSettings.SEO_DEFAULT_SUFFIX);

      // work-around: remove html tags
      const regex = /<[^>]*>/gim;
      const desc = hospital.hospital_desc.replace(regex, '');

      this.updateDescription(desc);
    }
  }

  setExpertMeta(expert: ExpertInfo) {
    if (expert) {
      this.updateTitle(expert.expert_name + AppSettings.SEO_DEFAULT_SUFFIX);

      // work-around: remove html tags
      const regex = /<[^>]*>/gim;
      const desc = expert.expertise.replace(regex, '');

      this.updateDescription(desc);
    }
  }

  setECCommodityMeta(commodity: CommodityInfo) {
    if (commodity) {
      this.updateTitle(commodity.title + AppSettings.SEO_DEFAULT_SUFFIX);

      // work-around: remove html tags
      const regex = /<[^>]*>/gim;
      const desc = commodity.content.replace(regex, '');

      this.updateDescription(desc);
    }
  }
}
