import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CodeZipCodeInfo } from '@mommy/models/CodeZipCodeInfo.model';
import { StorageService } from '@mommy/services/storage.service';
import { ZipcodeService } from '@mommy/services/zipcode/zipcode.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InitLocalCacheZipcode, LoadCacheZipcode } from './zipcode.actions';

export interface ZipcodeStateModel {
  loading: boolean;
  zipcodes: CodeZipCodeInfo[];
  hasCache: boolean;
}

const defaultZipcodeState = (): ZipcodeStateModel => {
  return {
    loading: false,
    zipcodes: [],
    hasCache: false,
  };
};

@State<ZipcodeStateModel>({
  name: 'ZipcodeState',
  defaults: defaultZipcodeState(),
})
@Injectable()
export class ZipcodeState {
  private _isServer: boolean = true;

  constructor(
    private storage: StorageService,
    private zipcodeSvc: ZipcodeService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  @Selector()
  static zipcodes(state: ZipcodeStateModel) {
    return state.zipcodes;
  }

  @Action(InitLocalCacheZipcode)
  async initLocalCacheZipcode(ctx: StateContext<ZipcodeStateModel>) {
    const _zipcodes: any = await this.storage.get('zipcodes');

    if (_zipcodes) {
      // do nothing
      this.getZipcodeFromServer(ctx);
    } else {
      this.getZipcodeFromServer(ctx, true);
    }
  }

  @Action(LoadCacheZipcode)
  async loadCacheZipcode(ctx: StateContext<ZipcodeStateModel>) {
    const state = ctx.getState();
    const _zipcodes: any = await this.storage.get('zipcodes');

    if (_zipcodes) {
      ctx.patchState({
        loading: false,
        zipcodes: _zipcodes,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  private async getZipcodeFromServer(
    ctx: StateContext<ZipcodeStateModel>,
    force?: boolean
  ) {
    // try read data from server
    console.log('getZipcodeFromServer');
    try {
      let zipcodes;
      if (this._isServer) {
        zipcodes = await this.zipcodeSvc.getAllZipcodeAtServer();
      } else {
        zipcodes = await this.zipcodeSvc.getAllZipcode(force);
      }
      console.log('zipcodes', zipcodes);
      await this.storage.set('zipcodes', zipcodes);
      await ctx.dispatch(new LoadCacheZipcode()).toPromise();
      console.log('load local cache zipcode success');
    } catch (error2) {
      console.warn('getAllZipcode error', error2);
    }
  }
}
