import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { caching } from 'cache-manager';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // private _storage: Storage | null | MemoryCache = null;
  private _storage: any = null;
  private _isServer: boolean;

  constructor(
    private storage: Storage,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
    this.init();
  }

  init(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this._isServer) {
        const memoryCache = await caching('memory', {
          max: 100,
          ttl: 1 * 100 /*milliseconds*/,
        });

        this._storage = memoryCache;
        console.log('@Server: StorageService: init done');
        resolve(true);
      } else {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        //await this.storage.defineDriver(CordovaSQLiteDriver);

        const storage = await this.storage.create();
        this._storage = storage;
        console.log('StorageService: init done');
        resolve(true);
      }
    });
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }

  public get(key: string) {
    return this._storage?.get(key);
  }

  public remove(key: string) {
    if (this._isServer) {
      return this._storage?.del(key);
    } else {
      return this._storage?.remove(key);
    }
  }
}
