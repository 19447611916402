import { isPlatformServer } from '@angular/common';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@mommy/environments/environment';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  retryWhen,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  Got401,
  Got403UserIsPending,
  Got404UserNotFound,
  RefreshAccessToken,
} from './../state/auth/auth.actions';
import { AuthService } from './auth/auth.service';
import { genericRetryStrategy } from './rxjs-util';
import { StateUtilsService } from './state-utils.service';
import { StorageService } from './storage.service';

// 定義 http 是否要處理 cache
export const HANDLE_CACHE = new HttpContextToken<boolean>(() => false);

// 定義 http 是否不要處理 token
export const IGNORE_TOKEN = new HttpContextToken<boolean>(() => false);

// 定義 http 是否不要處理 執行 Got401 action
export const IGNORE_GOT401ACTION = new HttpContextToken<boolean>(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private _isServer: boolean;

  constructor(
    public stateUtil: StateUtilsService,
    public store: Store,
    private authService: AuthService,
    private storage: StorageService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('TokenInterceptor: ', request.url);

    let newRequest;

    // appinfo.mommycareyou.com 不加 header, 否則會出現CORS問題
    if (request.url.includes('appinfo.mommycareyou.com')) {
      newRequest = request;
    } else {
      let token;
      if (request.url.includes(environment.EC_API_URL)) {
        token = this.stateUtil.getECAccessToken();
      } else {
        token = this.stateUtil.getAuthToken();
      }

      newRequest = this.addHeader(request, token);
      console.log('TokenInterceptor: newRequest', newRequest);
    }

    // // 函式： 更新token後retry api => 換成 private handle401Error 處理
    // const refreshTokenAndRetry = () => {
    //   console.log('refreshTokenAndRetry', request.url);
    //   return this.store.dispatch(new RefreshAccessToken()).pipe(
    //     take(1),
    //     switchMap(() => {
    //       const token = this.stateUtil.getAuthToken();
    //       return next.handle(this.addHeader(request, token));
    //     })
    //   );
    //   // return this.authService.refreshToken().pipe(
    //   //   take(1),
    //   //   switchMap((token) => {
    //   //     console.log('switchMap got token', token);
    //   //     return next.handle(this.addHeader(request, token));
    //   //   })
    //   // );
    // };

    return next.handle(newRequest).pipe(
      concatMap((evt) => {
        // console.log('intercept evt', evt);
        if (evt instanceof HttpResponse) {
          //console.log('http response', evt);

          // 處理 cachedkey
          const cachedkey = evt.headers.get('cachedkey');
          //console.log('cachedkey', cachedkey);

          if (cachedkey) {
            localStorage.setItem(request.url, cachedkey);
          }
          // 處理 cachedkey end

          // 這邊只有 200 的壯態才會執行這邊, graphql 都是 200 (即使裡面有錯誤 也是 200), 所以要在這裡做判斷
          // 一般的 restful api, 要在 下方的 rxjs error handling 那邊做處理
          if (evt.body) {
            try {
              // graphql response errors
              if (evt.body.errors) {
                if (
                  evt.body.errors[0]?.extensions?.response?.statusCode === 401
                ) {
                  console.warn(
                    'graphql got 401 error, try reload token and retry'
                  );
                  // return refreshTokenAndRetry(); // 注意是否會有 無窮迴圈 => 看過log, 不會loop
                  return this.handle401Error(request, next);
                } else if (
                  evt.body.errors[0]?.extensions?.response?.statusCode === 403
                ) {
                  console.warn('graphql got 403 error');
                  if (evt.body.errors[0]?.message === 'USER_IS_PENDING') {
                    console.warn(
                      'UserIsPending, dispatch Got403UserIsPending action.'
                    );
                    this.store.dispatch(new Got403UserIsPending());
                  }
                } else if (
                  evt.body.errors[0]?.extensions?.response?.statusCode === 404
                ) {
                  console.warn('graphql got 404 error');
                  if (evt.body.errors[0]?.message.includes('USER_NOT_FOUND')) {
                    console.warn(
                      'User not found, dispatch Got404UserNotFound action.'
                    );
                    this.store.dispatch(new Got404UserNotFound());
                  }
                }
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log('no body', evt);
          }
        }
        return of(evt);
      }),
      retryWhen(genericRetryStrategy()), // 什麼情境下要重試(目前只focus 500, server端 error時 重試一次)
      catchError((error: HttpErrorResponse) => {
        console.warn('TokenInterceptor: error', error);

        // 要排除 eline hospital 的 401 錯誤
        if (error.status === 401 && !error.url.includes('/hospital')) {
          console.warn('got 401 error', error, error.url);
          return this.handle401Error(request, next);
        } else if (error.status === 403) {
          console.warn('got 403 error', error);
          if (error.error?.message === 'USER_IS_PENDING') {
            console.warn('UserIsPending, dispatch Got403UserIsPending action.');
            this.store.dispatch(new Got403UserIsPending());
          }
        } else if (error.status === 404) {
          console.warn('got 404 error', error);
          if (error.error?.message.includes('USER_NOT_FOUND')) {
            console.warn(
              'USER_NOT_FOUND!, dispatch Got404UserNotFound action.'
            );
            this.store.dispatch(new Got404UserNotFound());
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.store.dispatch(new RefreshAccessToken()).pipe(
        switchMap(() => {
          this.isRefreshing = false;
          const new_token = this.stateUtil.getAuthToken();
          this.refreshTokenSubject.next(new_token);
          return next.handle(this.addHeader(request, new_token));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          const ignore_GOT401ACTION = request.context.get(IGNORE_GOT401ACTION);
          if (!ignore_GOT401ACTION) {
            this.store.dispatch(new Got401());
          }
          return throwError(err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addHeader(request, token)))
    );
  }

  private addHeader(request: HttpRequest<any>, token: string) {
    if (!token) {
      token = '';
    }

    const header: any = {};

    // 處理 token
    // let needToken = true;
    // const NO_NEED_TOKEN_URL = ['api/login2', 'api/signup2'];

    // NO_NEED_TOKEN_URL.forEach((elem) => {
    //   if (request.url.includes(elem)) {
    //     needToken = false;
    //   }
    // });

    // 處理 header token
    const ignoreToken = request.context.get(IGNORE_TOKEN);
    if (!ignoreToken && token) {
      header.Authorization = `Bearer ${token}`;
    }
    header['api-key'] = 'elineapp';
    // 處理 token end

    // 處理 cache
    const handleCache = request.context.get(HANDLE_CACHE);
    console.log('addToken handleCache', handleCache);

    // Server端(SSR) 不處理 cache
    if (!this._isServer) {
      if (handleCache) {
        const cachedkey = localStorage.getItem(request.url);
        header.cachedkey = cachedkey || '';
      }
    }

    // 處理 cache end

    console.log('addToken header', header);

    return request.clone({
      setHeaders: header,
    });
    // if (needToken) {
    //   const header: any = {
    //     'api-key': 'elineapp',
    //     Authorization: 'Bearer ' + token,
    //   };

    //   return request.clone({
    //     setHeaders: header,
    //   });
    // } else {
    //   const header: any = {
    //     'api-key': 'elineapp',
    //   };
    //   return request.clone({
    //     setHeaders: header,
    //   });
    // }
  }
}
