import { FirebaseUtilsService } from '@mommy/services/firebase-utils.service';
import { PushNotificationService } from '@mommy/services/push-notification.service';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LineLogin } from '@awesome-cordova-plugins/line-login/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicConfig } from '@ionic/core';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { environment } from '@mommy/environments/environment';
import { Api } from '@mommy/services/api';
import { ApiOmProvider } from '@mommy/services/api-om';
import { ApolloClientService } from '@mommy/services/apollo-client.service';
import { AuthGuard } from '@mommy/services/auth-guard/auth.guard';
import { BabyDiaryPostService } from '@mommy/services/baby-diary-post/baby-diary-post.service';
import { CacheService } from '@mommy/services/cache.service';
import { CapUtilService } from '@mommy/services/caputil.service';
import { ECApi } from '@mommy/services/ec/ec-api';
import { ECCommodityService } from '@mommy/services/ec/ec-commodity.service';
import { ECOrderService } from '@mommy/services/ec/ec-order.service';
import { ElineApi } from '@mommy/services/eline-api';
import { ExpertService } from '@mommy/services/expert/expert.service';
import { HospitalService } from '@mommy/services/hospital/hospital.service';
import { LineUtilService } from '@mommy/services/line/line-util.service';
import { LookupService } from '@mommy/services/lookup/lookup.service';
import { LuckyService } from '@mommy/services/lucky/lucky.service';
import { MaternityKitService } from '@mommy/services/maternitykit/maternitykit.service';
import { MetaService } from '@mommy/services/meta.service';
import { PostService } from '@mommy/services/post/post.service';
import { PregnancyPostService } from '@mommy/services/pregnancy-post/pregnancy-post.service';
import { StateUtilsService } from '@mommy/services/state-utils.service';
import { StorageService } from '@mommy/services/storage.service';
import { TaskService } from '@mommy/services/task/task.service';
import { TaxonomyService } from '@mommy/services/taxonomy/taxonomy.service';
import { TokenInterceptor } from '@mommy/services/token.interceptor';
import { UtilService } from '@mommy/services/util.service';
import { VoteService } from '@mommy/services/vote/vote.service';
import { ZipcodeService } from '@mommy/services/zipcode/zipcode.service';
import { ComponentsModule } from '@mommy/shared/components.module';
import { StateModule } from '@mommy/state/state.module';
import { QRCodeModule } from 'angularx-qrcode';
import { ApolloModule } from 'apollo-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql/graphql.module';

export function get_firebase_config() {
  //const mytest = localStorage.getItem('mytest');
  let mytest = 'NO';
  let firebase_config: any;

  if (mytest === 'YES') {
    firebase_config = {
      apiKey: 'AIzaSyAxlKr7CMp769ZB89gaMBfldrREN63H3Kw',
      authDomain: 'elineapptest.firebaseapp.com',
      databaseURL: 'https://elineapptest.firebaseio.com',
      projectId: 'elineapptest',
      storageBucket: 'elineapptest.appspot.com',
      messagingSenderId: '79013763327',
    };
  } else {
    firebase_config = environment.firebaseConfig;
  }

  return firebase_config;
}

const getConfig = () => {
  // if (isPlatform('ios')) {
  //   return {
  //     backButtonText: '返回',
  //   };
  // } else if (isPlatform('android')) {
  // }

  // return {};

  // 先調整為 ios 的設定, 如果要分別設定 by platform setting, 需要調整比較多的程式
  const config: IonicConfig = {
    mode: 'ios',
    backButtonText: '返回',
    swipeBackEnabled: false,
  };
  return config;
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    GraphQLModule,
    ApolloModule,
    AngularFireModule.initializeApp(get_firebase_config()),
    AngularFireAuthModule,
    QRCodeModule,
    NgxLinkifyjsModule.forRoot({
      enableMention: false, // optional - default true
    }),
    IonicModule.forRoot(getConfig()),
    IonicStorageModule.forRoot({
      name: '__mommy',
      // driverOrder: ['localstorage', 'sqlite'],
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
      // driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    AppRoutingModule,
    SwiperModule,
    ComponentsModule,
    StateModule,
  ],
  providers: [
    FirebaseX,
    InAppBrowser,
    LineLogin,
    BarcodeScanner,
    CallNumber,
    Api,
    ApiOmProvider,
    ElineApi,
    ECApi,
    ECOrderService,
    ECCommodityService,
    FirebaseUtilsService,
    PushNotificationService,
    LineUtilService,
    AuthGuard,
    TaskService,
    TaxonomyService,
    LookupService,
    ZipcodeService,
    ExpertService,
    HospitalService,
    MaternityKitService,
    LuckyService,
    PregnancyPostService,
    BabyDiaryPostService,
    PostService,
    VoteService,
    CacheService,
    CapUtilService,
    UtilService,
    StateUtilsService,
    StorageService,
    MetaService,
    ApolloClientService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (stateUtils: StateUtilsService) => () => stateUtils.initApp(),
      deps: [StateUtilsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(private injector: Injector) {
    AppModule.injector = injector;
  }
}
