import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TrashObject } from '@mommy/models/Comm.model';
import { VoteInfo } from '@mommy/models/VoteInfo.model';
import { MemberVoteService } from '@mommy/services/member/member-vote.service';
import { StorageService } from '@mommy/services/storage.service';
import { VoteService } from '@mommy/services/vote/vote.service';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { AppSettings } from 'app/app.settings';
import { parseISO } from 'date-fns';
import * as _ from 'lodash';
import { AppInitState } from '../app-init/app-init.state';
import { RefreshMommyUser } from '../user/user.actions';
import {
  AddMemberVote,
  AddVoteReportCommentId,
  GetVoteDetail,
  InitLocalCacheVote,
  LoadCacheVote,
  RefreshVote,
  SetVotesToVoteState,
  UpdateMemberVote,
} from './vote.actions';

export interface VoteStateModel {
  loading: boolean;
  votes: VoteInfo[];
  detailVotes: VoteInfo[];
  hasCache: boolean;
  reportVoteCommentIds: number[]; // 被檢舉的vote comment id
}

const defaultVoteState = (): VoteStateModel => {
  return {
    loading: false,
    votes: [],
    detailVotes: [],
    hasCache: false,
    reportVoteCommentIds: [],
  };
};

@State<VoteStateModel>({
  name: 'VoteState',
  defaults: defaultVoteState(),
})
@Injectable()
export class VoteState implements NgxsAfterBootstrap {
  private _isServer: boolean;

  constructor(
    private storage: StorageService,
    private voteSvc: VoteService,
    private memberVoteService: MemberVoteService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  async ngxsAfterBootstrap(ctx: StateContext<VoteStateModel | null>) {
    console.log('[VoteState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  static detailVote(vote_id: number) {
    return createSelector([VoteState], (state: VoteStateModel) => {
      const vote = _.cloneDeep(_.find(state.detailVotes, { vote_id }));

      if (vote) {
        // 過濾掉被檢舉的評論
        if (state.reportVoteCommentIds.length > 0) {
          const new_comments = _.filter(vote.vote_comments, (comment) => {
            const index = _.findIndex(state.reportVoteCommentIds, (id) => {
              return id === comment.vote_comment_id;
            });
            return index === -1;
          });
          vote.vote_comments = new_comments;
        }
      }
      return vote;
    });
  }

  // Vote state join AppInitState.vote_trash_objects
  @Selector([VoteState, AppInitState.vote_trash_objects])
  static votes(state: VoteStateModel, trash_objects: TrashObject[]) {
    return _.chain(state.votes)
      .filter((vote) => {
        return (
          _.findIndex(trash_objects, {
            object_id: vote.vote_id,
            trash_type: 'VOTE',
          }) === -1
        );
      })
      .value();
  }

  // 取得已發佈的 votes
  // 狀態為 Y, 且 vote_start_date < 今天
  // 依照 vote_start_date 排序
  @Selector([VoteState.votes])
  static publishVotes(votes: VoteInfo[]) {
    return _.chain(votes)
      .filter((vote) => {
        const _vote_start_date = parseISO(vote.vote_start_date);
        return vote.vote_status === 'Y' && _vote_start_date < new Date();
      })
      .orderBy(['vote_start_date'], ['desc'])
      .value();
  }

  @Action(InitLocalCacheVote)
  async initLocalCacheVote(ctx: StateContext<VoteStateModel>) {
    console.log('[Action] initLocalCacheVote');

    try {
      await ctx.dispatch(new LoadCacheVote()).toPromise();
      console.log('load local cache votes success');
      // 再呼叫 getVotesFromServer 來更新 local cache
      //this.getVotesFromServer(ctx);
      await ctx.dispatch(new RefreshVote()).toPromise();
    } catch (error) {
      console.warn('LoadCacheVote error', error);
      // 如果沒有 cache, 就去 server 取
      await ctx.dispatch(new RefreshVote()).toPromise();
    }
  }

  @Action(LoadCacheVote)
  async loadCacheVote(ctx: StateContext<VoteStateModel>) {
    console.log('[Action] LoadCacheVote');

    const _votes: any = await this.storage.get(AppSettings.CACHE_KEY_VOTE_LIST);

    // 處理本機 檢舉vote評論的紀錄
    let _reportVoteCommentIds: any = await this.storage.get(
      'reportVoteCommentIds'
    );
    if (!_reportVoteCommentIds) {
      _reportVoteCommentIds = [];
    }

    if (_votes) {
      ctx.patchState({
        loading: false,
        votes: _votes,
        hasCache: true,
        reportVoteCommentIds: _reportVoteCommentIds,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshVote)
  async refreshVote(ctx: StateContext<VoteStateModel>) {
    console.log('[Action] RefreshVote');
    this.getVotesFromServer(ctx);
  }

  @Action(AddMemberVote)
  async addMemberVote(ctx: StateContext<VoteStateModel>, { payload }) {
    console.log('[Action] AddMemberVote');
    try {
      await this.memberVoteService.add(payload);
      await ctx
        .dispatch([new RefreshVote(), new RefreshMommyUser()])
        .toPromise();
    } catch (error) {
      console.warn('AddMemberVote error', error);
      throw error;
    }
  }

  @Action(UpdateMemberVote)
  async UpdateMemberVote(ctx: StateContext<VoteStateModel>, { payload }) {
    console.log('[Action] UpdateMemberVote');
    try {
      await this.memberVoteService.update(payload);
      await ctx
        .dispatch([new RefreshVote(), new RefreshMommyUser()])
        .toPromise();
    } catch (error) {
      console.warn('UpdateMemberVote error', error);
      throw error;
    }
  }

  @Action(SetVotesToVoteState)
  async SetVotesToVoteState(
    ctx: StateContext<VoteStateModel>,
    action: SetVotesToVoteState
  ) {
    console.log('[Action] SetVotesToVoteState');
    const state = ctx.getState();

    ctx.patchState({
      votes: action.payload,
    });
  }

  private async getVotesFromServer(ctx: StateContext<VoteStateModel>) {
    // try read data from server
    console.log('getVotesFromServer');
    try {
      let votes;
      if (this._isServer) {
        votes = await this.voteSvc.getVoteListAtServer();
      } else {
        votes = await this.voteSvc.getVoteList();
      }

      console.log('votes', votes);
      await ctx.dispatch(new LoadCacheVote()).toPromise();
      console.log('load local cache votes success');
    } catch (error2) {
      console.warn('getVoteList error', error2);
    }
  }

  @Action(GetVoteDetail)
  async GetVoteDetail(
    ctx: StateContext<VoteStateModel>,
    action: GetVoteDetail
  ) {
    console.log('[Action] GetVoteDetail');
    const state = ctx.getState();

    // 先載入 local cache
    const _vote: any = await this.storage.get('vote-' + action.vote_id);

    // await this.util.sleep(3000);
    const _detailVotes = state.detailVotes;
    const new_detailVotes = _.cloneDeep(_detailVotes);

    if (_vote) {
      // 先判斷 detailVotes 是否已經存在, 如果有就置換, 沒有就新增至 detailVotes
      const index = _.findIndex(new_detailVotes, {
        vote_id: action.vote_id,
      });
      if (index > -1) {
        new_detailVotes.splice(index, 1, _vote);
      } else {
        new_detailVotes.push(_vote);
      }

      ctx.patchState({
        detailVotes: new_detailVotes,
      });

      this.getVoteDetailFromServer(ctx, action.vote_id); // 再呼叫 getVoteDetail 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getVoteDetailFromServer(ctx, action.vote_id);
    }
  }

  // 增加 vote 檢舉評論id 的紀錄
  @Action(AddVoteReportCommentId)
  async AddVoteReportCommentId(
    ctx: StateContext<VoteStateModel>,
    { comment_id }: AddVoteReportCommentId
  ) {
    console.log('[Action] AddVoteReportCommentId');

    const state = ctx.getState();

    const _reportVoteCommentIds = _.cloneDeep(state.reportVoteCommentIds);
    _reportVoteCommentIds.push(comment_id);

    await this.storage.set('reportVoteCommentIds', _reportVoteCommentIds);

    ctx.patchState({
      reportVoteCommentIds: _reportVoteCommentIds,
    });
  }

  private async getVoteDetailFromServer(
    ctx: StateContext<VoteStateModel>,
    vote_id: number
  ) {
    // try read data from server
    console.log('getVoteDetailFromServer');
    try {
      const vote = await this.voteSvc.getVoteDetail(vote_id);
      console.log('vote', vote);
      await this.storage.set('vote-' + vote_id, vote);

      const state = ctx.getState();
      const _detailVotes = state.detailVotes;
      const new_detailVotes = _.cloneDeep(_detailVotes);

      const index = _.findIndex(new_detailVotes, { vote_id });
      if (index > -1) {
        new_detailVotes.splice(index, 1, vote);
      } else {
        new_detailVotes.push(vote);
      }

      ctx.patchState({
        detailVotes: new_detailVotes,
      });
    } catch (error2) {
      console.warn('getVoteDetail error', error2);
    }
  }
}
