import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { Api } from '../api';
import { HANDLE_CACHE } from '../token.interceptor';
const fs = require('fs');

@Injectable({
  providedIn: 'root',
})
export class TaxonomyService {
  constructor(private api: Api) {
    console.log('Hello TaxonomyService');
  }

  getAllTaxonomy(force?: boolean): Promise<any> {
    const endpoint = `v1/codes/reference-codes/all-taxonomies`;

    return new Promise(async (resolve, reject) => {
      try {
        const context = new HttpContext();
        context.set(HANDLE_CACHE, force ? false : true);

        const result: APIResponse = (await this.api
          .get(endpoint, null, { context })
          .toPromise()) as any;
        // console.log('result', result);
        if (result.returnCode === 'ERROR') {
          reject(result);
        } else {
          resolve(result.data);
        }
      } catch (error) {
        if (error.status !== 304) {
          console.error('getAllTaxonomy error', error);
        }
        reject(error);
      }
    });
  }

  // 這個方法是為了在 server side 取得 taxonomy(Angular SSR)
  // 因為在 server side 不能使用 storage, 所以只能先把 taxonomy 儲存到檔案
  // 邏輯: 先檢查檔案是否有 cache, 若無, 則呼叫 api 取得 並儲存到檔案
  getAllTaxonomyAtServer(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        // 先從檔案讀取, 若無, 則呼叫 api 取得 並儲存到檔案
        const filename = 'cache-all-taxonomies.json';
        const filepath = `./${filename}`;

        console.log('@Server: getAllTaxonomyAtServer filepath', filepath);
        fs.readFile(filepath, 'utf8', async (err, data) => {
          if (err) {
            console.error('@Server: getAllTaxonomyAtServer error', err);
            reject(err);
          }

          if (data) {
            const data_obj = JSON.parse(data);
            console.log(
              '@Server: getAllTaxonomyAtServer got cache data, rows: ',
              data_obj?.length
            );
            resolve(data_obj);
          } else {
            console.log(
              '@Server: getAllTaxonomyAtServer got no cache, call api to get data'
            );
            const new_data = await this.getAllTaxonomy();
            fs.writeFile(filepath, JSON.stringify(new_data), (err) => {
              if (err) {
                console.error('@Server: getAllTaxonomyAtServer error', err);
                reject(err);
              }

              console.log('@Server: getAllTaxonomyAtServer save cache success');
              resolve(new_data);
            });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
