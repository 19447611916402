import { Injectable } from '@angular/core';
import { CodeTaxonomyInfo } from '@mommy/models/CodeTaxonomyInfo.model';
import { TrashObject } from '@mommy/models/Comm.model';
import { HospitalInfo } from '@mommy/models/HospitalInfo.model';
import { HospitalService } from '@mommy/services/hospital/hospital.service';
import { OthersService } from '@mommy/services/others/others.service';
import { StorageService } from '@mommy/services/storage.service';
import { UtilService } from '@mommy/services/util.service';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import * as _ from 'lodash';
import { AppSettings } from '../../app.settings';
import { AppInitState } from '../app-init/app-init.state';
import { AppUIState } from '../app-ui/app-ui.state';
import {
  CalculateHospitalDistance,
  GetHospitalDetail,
  HospitalClickLog,
  HospitalElineClickLog,
  InitLocalCacheHospitals,
  LoadCacheHospitals,
  RefreshHospitals,
  SetHospitalsToHospitalState,
} from './hospital.actions';

export interface HospitalStateModel {
  loading: boolean;
  hospitals: HospitalInfo[];
  detailHospitals: HospitalInfo[];
  hasCache: boolean;
}

const defaultHospitalState = (): HospitalStateModel => {
  return {
    loading: false,
    hospitals: [],
    detailHospitals: [],
    hasCache: false,
  };
};

@State<HospitalStateModel>({
  name: 'HospitalState',
  defaults: defaultHospitalState(),
})
@Injectable()
export class HospitalState implements NgxsAfterBootstrap {
  constructor(
    private storage: StorageService,
    private hospitalService: HospitalService,
    public util: UtilService,
    private othersService: OthersService
  ) {}

  async ngxsAfterBootstrap(ctx: StateContext<HospitalStateModel | null>) {
    console.log('[HospitalState] ngxsAfterBootstrap');

    // try {
    //   await ctx.dispatch(new LoadCacheMaternityKit()).toPromise();
    //   console.log('load local cache maternitykit success');
    //   // 再呼叫 getAllMaternityKit 來更新 local cache
    //   this.getMaternityKitFromServer(ctx);
    // } catch (error) {
    //   console.warn('LoadCacheMaternityKit error', error);
    //   // 如果沒有 cache, 就去 server 取
    //   this.getMaternityKitFromServer(ctx);
    // }
  }

  // 建立 dynamic selector, 傳入 hospital_id, 回傳對應的 hospital
  // 這個方法支援 memoized selector
  // Note that each of these selectors have their own separate memoization.
  // Even if two dynamic selectors created in this way are provided the same argument, they will have separate memoization.
  static detailHospital(hospital_id: number) {
    return createSelector([HospitalState], (state: HospitalStateModel) => {
      return _.find(state.detailHospitals, { hospital_id });
    });
  }

  // 醫院 state join AppInitState.hospital_trash_objects
  @Selector([HospitalState, AppInitState.hospital_trash_objects])
  static hospitals(state: HospitalStateModel, trash_objects: TrashObject[]) {
    return _.chain(state.hospitals)
      .filter((hospital) => {
        return (
          _.findIndex(trash_objects, {
            object_id: hospital.hospital_id,
            trash_type: 'HOSPITAL',
          }) === -1
        );
      })
      .value();
  }

  // 診所列表頁, 依據 距離 + 更新時間 排序, 取前 n 筆 (由 AppUIState.page_hospital_list_list_count 控制要撈取幾筆)
  @Selector([HospitalState.hospitals, AppUIState.page_hospital_list_list_count])
  static hospitalListOrderByDistance(
    hospitals: HospitalInfo[],
    page_hospital_list_list_count: number
  ) {
    console.log('hospitalListOrderByDistance');
    console.log('page_hospital_list_list_count', page_hospital_list_list_count);

    return _.chain(hospitals)
      .orderBy(['distance', 'updated_at'], ['asc', 'desc'])
      .take(page_hospital_list_list_count)
      .value();
  }

  // 診所列表, 依據 zip_code 過濾, order by 更新時間
  @Selector([
    HospitalState.hospitals,
    AppUIState.page_hospital_list_category_result_zipcode,
  ])
  static hospitalListByZipCode(
    hospitals: HospitalInfo[],
    page_hospital_list_category_result_zipcode: string
  ) {
    console.log('hospitalListByZipCode');
    console.log(
      'page_hospital_list_category_result_zipcode',
      page_hospital_list_category_result_zipcode
    );

    return _.chain(hospitals)
      .filter((hospital) => {
        if (page_hospital_list_category_result_zipcode) {
          return (
            hospital.zip_code === page_hospital_list_category_result_zipcode
          );
        } else {
          return true;
        }
      })
      .orderBy(['updated_at'], ['desc'])
      .value();
  }

  // 診所搜尋頁, 依據 關鍵字/熱門分類/科別專長/...  過濾, order by 更新時間
  @Selector([
    HospitalState.hospitals,
    AppUIState.page_hospital_search_keyword,
    AppUIState.page_hospital_search_list_count,
    AppUIState.page_hospital_search_top_categorys,
    AppUIState.page_hospital_search_subject_classes,
    AppUIState.page_hospital_search_medical_cares,
    AppUIState.page_hospital_search_hardware_equipments,
    AppUIState.page_hospital_search_life_functions,
    AppUIState.page_hospital_search_section2,
    AppUIState.page_hospital_search_city,
    AppUIState.page_hospital_search_district,
  ])
  static hospitalListWithConditions(
    hospitals: HospitalInfo[],
    page_hospital_search_keyword: string,
    page_hospital_search_list_count: number,
    page_hospital_search_top_categorys: CodeTaxonomyInfo[],
    page_hospital_search_subject_classes: CodeTaxonomyInfo[],
    page_hospital_search_medical_cares: CodeTaxonomyInfo[],
    page_hospital_search_hardware_equipments: CodeTaxonomyInfo[],
    page_hospital_search_life_functions: CodeTaxonomyInfo[],
    page_hospital_search_section2: CodeTaxonomyInfo[],
    page_hospital_search_city: string,
    page_hospital_search_district: string[]
  ) {
    console.log('hospitalListWithConditions');
    console.log('page_hospital_search_keyword', page_hospital_search_keyword);
    console.log(
      'page_hospital_search_list_count',
      page_hospital_search_list_count
    );
    console.log(
      'page_hospital_search_top_categorys',
      page_hospital_search_top_categorys
    );

    return (
      _.chain(hospitals)
        .filter((hospital) => {
          // 關鍵字
          if (page_hospital_search_keyword) {
            return (
              hospital.hospital_name.indexOf(page_hospital_search_keyword) > -1
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 熱門分類
          if (page_hospital_search_top_categorys.length > 0) {
            return (
              _.intersectionBy(
                hospital.top_categorys,
                page_hospital_search_top_categorys,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 科別專長
          if (page_hospital_search_subject_classes.length > 0) {
            return (
              _.intersectionBy(
                hospital.subject_classes,
                page_hospital_search_subject_classes,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 醫療護理
          if (page_hospital_search_medical_cares.length > 0) {
            return (
              _.intersectionBy(
                hospital.medical_cares,
                page_hospital_search_medical_cares,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 硬體設備
          if (page_hospital_search_hardware_equipments.length > 0) {
            return (
              _.intersectionBy(
                hospital.hardware_equipments,
                page_hospital_search_hardware_equipments,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 生活機能
          if (page_hospital_search_life_functions.length > 0) {
            return (
              _.intersectionBy(
                hospital.life_functions,
                page_hospital_search_life_functions,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 區域
          if (page_hospital_search_section2.length > 0) {
            return (
              _.intersectionBy(
                hospital.section2,
                page_hospital_search_section2,
                'taxonomy_id'
              ).length > 0
            );
          } else {
            return true;
          }
        })
        .filter((hospital) => {
          // 縣市/鄉鎮市區
          if (page_hospital_search_city) {
            if (page_hospital_search_district?.length > 0) {
              return (
                hospital.city === page_hospital_search_city &&
                page_hospital_search_district.includes(hospital.district)
              );
            } else {
              return hospital.city === page_hospital_search_city;
            }
          } else {
            return true;
          }
        })
        .orderBy(['updated_at'], ['desc'])
        // .take(page_hospital_search_list_count) // 先移除限制筆數, 先全部顯示
        .value()
    );
  }

  //#region ========== Actions ==========
  @Action(InitLocalCacheHospitals)
  async initLocalCacheHospitals(ctx: StateContext<HospitalStateModel>) {
    console.log('[Action] InitLocalCacheHospitals');

    // const _hospitals: any = await this.storage.get(
    //   AppSettings.CACHE_KEY_HOSPITAL_LIST
    // );

    // if (_hospitals) {
    //   // do nothing
    // } else {
    //   this.getHospitalsFromServer(ctx);
    // }

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheHospitals()).toPromise();
      console.log('load local cache hospital success');
      // 再呼叫 getHospitalsFromServer 來更新 local cache
      // this.getHospitalsFromServer(ctx);
      await ctx.dispatch(new RefreshHospitals()).toPromise();
    } catch (error) {
      console.warn('LoadCacheHospitals error', error);
      // 如果沒有 cache, 就去 server 取
      // this.getHospitalsFromServer(ctx);
      await ctx.dispatch(new RefreshHospitals()).toPromise();
    }
  }

  @Action(LoadCacheHospitals)
  async loadCacheHospitals(ctx: StateContext<HospitalStateModel>) {
    console.log('[Action] LoadCacheHospitals');

    const state = ctx.getState();
    const _hospitals: any = await this.storage.get(
      AppSettings.CACHE_KEY_HOSPITAL_LIST
    );

    if (_hospitals) {
      ctx.patchState({
        loading: false,
        hospitals: _hospitals,
        hasCache: true,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshHospitals)
  async refreshHospitals(ctx: StateContext<HospitalStateModel>) {
    console.log('[Action] RefreshHospitals');
    this.getHospitalsFromServer(ctx);
  }

  @Action(GetHospitalDetail)
  async getHospitalDetail(
    ctx: StateContext<HospitalStateModel>,
    action: GetHospitalDetail
  ) {
    console.log('[Action] GetHospitalDetail');
    const state = ctx.getState();

    // 先載入 local cache
    const _hospital: any = await this.storage.get(
      'hospital-' + action.hospital_id
    );

    // await this.util.sleep(3000);
    const _detailHospitals = state.detailHospitals;
    const new_detailHospitals = _.cloneDeep(_detailHospitals);

    if (_hospital) {
      // 先判斷 detailHospitals 是否已經存在, 如果有就置換, 沒有就新增至 detailHospitals
      const index = _.findIndex(new_detailHospitals, {
        hospital_id: action.hospital_id,
      });
      if (index > -1) {
        new_detailHospitals.splice(index, 1, _hospital);
      } else {
        new_detailHospitals.push(_hospital);
      }

      ctx.patchState({
        detailHospitals: new_detailHospitals,
      });

      this.getHospitalDetailFromServer(ctx, action.hospital_id); // 再呼叫 getHospitalDetail 來更新資料
    } else {
      // 如果沒有 cache, 就去 server 取
      await this.getHospitalDetailFromServer(ctx, action.hospital_id);
    }
  }

  @Action(CalculateHospitalDistance)
  async calculateHospitalDistance(
    ctx: StateContext<HospitalStateModel>,
    action: CalculateHospitalDistance
  ) {
    console.log('[Action] CalculateHospitalDistance');
    const state = ctx.getState();

    const hospitals = _.map(state.hospitals, (hospital: HospitalInfo) => {
      const distance = this.util.getDistanceFromLatLonInKm(
        action.user_lat,
        action.user_lon,
        hospital.latitude || 0,
        hospital.longitude || 0
      );
      return {
        ...hospital,
        distance,
      };
    });

    ctx.patchState({
      hospitals,
    });
  }

  @Action(SetHospitalsToHospitalState)
  async SetHospitalsToHospitalState(
    ctx: StateContext<HospitalStateModel>,
    action: SetHospitalsToHospitalState
  ) {
    console.log('[Action] SetHospitalsToHospitalState');
    const state = ctx.getState();

    ctx.patchState({
      hospitals: action.payload,
    });
  }

  @Action(HospitalClickLog)
  async HospitalClickLog(
    ctx: StateContext<HospitalStateModel>,
    { hospital_id }: HospitalClickLog
  ) {
    console.log('[Action] HospitalClickLog');

    try {
      const result: any = await this.othersService.hospitalClickLog(
        hospital_id
      );
      console.log('hospitalClickLog result', result);
    } catch (error) {
      console.error('hospitalClickLog error', error);
    }
  }

  @Action(HospitalElineClickLog)
  async HospitalElineClickLog(
    ctx: StateContext<HospitalStateModel>,
    { hospital_id }: HospitalElineClickLog
  ) {
    console.log('[Action] HospitalElineClickLog');

    try {
      const result: any = await this.othersService.hospitalElineClickLog(
        hospital_id
      );
      console.log('hospitalElineClickLog result', result);
    } catch (error) {
      console.error('hospitalElineClickLog error', error);
    }
  }
  //#endregion ======================== Action ========================

  private async getHospitalsFromServer(ctx: StateContext<HospitalStateModel>) {
    // try read data from server
    console.log('getHospitalsFromServer');
    try {
      const hospitals: any = await this.hospitalService.getHospitalList();
      // console.log('hospitals', hospitals);
      // await this.storage.set(AppSettings.CACHE_KEY_HOSPITAL_LIST, hospitals);
      // await ctx.dispatch(new LoadCacheHospitals()).toPromise();
      ctx.patchState({
        loading: false,
        hospitals,
        hasCache: true,
      });
      console.log('load local cache hospitals success');
    } catch (error2) {
      console.warn('getHospitalList error', error2);
    }
  }

  private async getHospitalDetailFromServer(
    ctx: StateContext<HospitalStateModel>,
    hospital_id: number
  ) {
    // try read data from server
    console.log('getHospitalDetailFromServer');
    try {
      const hospital = await this.hospitalService.getHospitalDetail(
        hospital_id
      );
      console.log('hospital', hospital);
      await this.storage.set('hospital-' + hospital_id, hospital);

      const state = ctx.getState();
      const _detailHospitals = state.detailHospitals;
      const new_detailHospitals = _.cloneDeep(_detailHospitals);

      const index = _.findIndex(new_detailHospitals, { hospital_id });
      if (index > -1) {
        new_detailHospitals.splice(index, 1, hospital);
      } else {
        new_detailHospitals.push(hospital);
      }

      ctx.patchState({
        detailHospitals: new_detailHospitals,
      });
    } catch (error2) {
      console.warn('getHospitalDetail error', error2);
    }
  }
}
