import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilService } from '@mommy/services/util.service';

@Component({
  selector: 'app-download-app-modal',
  templateUrl: './download-app-modal.page.html',
  styleUrls: ['./download-app-modal.page.scss'],
})
export class DownloadAppModalPage implements OnInit {
  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private util: UtilService
  ) {}

  async ngOnInit() {}

  download() {
    window.open(
      'https://appinfo.mommycareyou.com/appdetect/mcyu.html',
      '_blank'
    );
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
