import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  if (environment.WEB_FLAG) {
    // SSR mode, error 都不顯示
    if (window) {
      window.console.log =
        window.console.warn =
        window.console.info =
        window.console.error =
          function () {
            // Don't log anything.
          };
    }
  } else {
    if (window) {
      window.console.log =
        window.console.warn =
        window.console.info =
          function () {
            // Don't log anything.
          };
    }
  }
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
