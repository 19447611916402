/* eslint-disable no-throw-literal */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Store } from '@ngxs/store';
// import * as firebase from 'firebase';
import { ElineUser } from '@mommy/models/Eline.model';
import {
  differenceInDays,
  format,
  fromUnixTime,
  parse,
  parseISO,
} from 'date-fns';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ElineApi } from '../eline-api';

@Injectable({
  providedIn: 'root',
})
export class ElineUserService {
  userId: string = null;
  private _isAuthenticated = new BehaviorSubject(false);
  isLogined: boolean;

  idToken;

  constructor(
    private platform: Platform,
    private afAuth: AngularFireAuth,
    public api: ElineApi,
    private storage: Storage,
    private store: Store
  ) {
    console.log('hello ElineUserService');
  }

  // signup with email
  signup2(data): Promise<any> {
    let endpoint = 'signup2';
    console.log(data);

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'signup2';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // login with email
  login2(data): Promise<any> {
    let endpoint = 'login2';
    console.log(data);

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'login2';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  refresh_fb_custom_token(user_id): Promise<any> {
    let endpoint = 'refresh_fb_custom_token';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = { user_id };

      try {
        const result: any = await this.api.post(endpoint, params);
        console.log('refresh_fb_custom_token', result);
        resolve(result.data.firebase_token);
      } catch (error) {
        console.error('refresh_fb_custom_token error', error);
        reject(error);
      }
    });
  }

  // 登出 eline
  logout(data): Promise<any> {
    let endpoint = 'logout';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'logout';
      params.data = data;

      try {
        const result: any = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        console.error('logout error', error);
        reject(error);
      }
    });
  }

  // firebase login with credential
  fb_login2(data): Promise<any> {
    console.log('credential:' + data);
    return <Promise<any>>this.afAuth.signInWithCredential(data);
    // .catch(this.handleError);
  }

  fb_login(data): Promise<any> {
    console.log('custom_token:' + data);
    return this.afAuth.signInWithCustomToken(data);

    // console.log('custom_token:' + data);
    // return fromPromise(<Promise<any>>this.afAuth.auth.signInWithCustomToken(data));
    // .catch(this.handleError);
  }

  fb_logout(): Promise<any> {
    console.log('fb_logout');
    return this.afAuth.signOut();
  }

  // mommycareyou 使用
  get_user_me(auth_token?: string): Promise<any> {
    let endpoint = 'mappv1/user/me';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = {};

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  get_user_code(auth_token?: string): Promise<any> {
    let endpoint = 'user/user_code';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = {};

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // mommycare you 使用
  update_user_me(data: any, auth_token?: string): Promise<any> {
    let endpoint = 'mappv1/user/me';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'upd';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  register_device2(data: any, auth_token?: string): Promise<any> {
    let endpoint = 'appv1/user/register_device2';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'upser';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  change_password(data: any, auth_token?: string): Promise<any> {
    let endpoint = 'changepassword';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'changepassword';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  verifyToken(): Promise<any> {
    let endpoint = 'verifytoken';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'verifytoken';
      params.data = {};

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  refreshToken(data: any): Promise<any> {
    let endpoint = 'mappv1/refresh_token';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'refresh_token';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 處理facebook,google login後的認證
  oauth(data): Promise<any> {
    let endpoint = 'oauth';
    console.log(data);

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'oauth';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 邀請家人加入諮詢
  create_chat_invite(data: any, auth_token?) {
    let endpoint = 'channel/invite/create';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'upsert';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 家人模式邀請list
  get_chat_invite(auth_token?) {
    let endpoint = 'channel/invite/list';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 家人模式邀請回覆
  reply_chat_invite(data: any, auth_token?) {
    let endpoint = 'channel/invite/update';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'upd';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 家人模式,移除邀請紀錄
  remove_chat_invite(data: any, auth_token?) {
    let endpoint = 'channel/relation/remove';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'del';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 家人模式,退出該channel的邀請
  quit_chat_invite(data: any, auth_token?) {
    let endpoint = 'channel/relation/quit';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'del';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 家人模式,取得channel的家人list
  get_chat_relation(data: any, auth_token?) {
    let endpoint = 'channel/relation';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // 家人模式取消,需要重整fcm topic訂閱(from server)
  create_fcm_procss(data: any, auth_token?) {
    let endpoint = 'fcm_procss/create';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'ins';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  // map user object
  // ex: 家人模式的欄位
  map_user_object(user: ElineUser) {
    console.log('map_user_object', user);
    if (user.account_type == null) {
      user.account_type = 'duty';
    }

    //整理家人模式
    if (user.roles === 'user') {
      user.family = [];
      user.family_u = [];
      user.family_without_self = []; // 家人的uid,不包含自己
      user.family_channels = [];
      user.family.push(user.user_id);
      user.family_u.push('u' + user.user_id);
      _.forEach(user.chat_channel_invite_to, (elem) => {
        user.family.push(parseInt(elem.from_user_id, 10));
        user.family_u.push('u' + parseInt(elem.from_user_id, 10));
        user.family_without_self.push(parseInt(elem.from_user_id, 10));
        user.family_channels.push(elem.channel_id);
      });
    }

    // 孕期處理, 先判斷預產期, 沒有再判斷lmp_date
    const today = new Date();

    if (user.baby_due_date) {
      const baby_due_date = parseISO(user.baby_due_date);
      const days = differenceInDays(baby_due_date, today);

      user.pregnancy = '';
      if (days >= 0 && days < 28) {
        user.pregnancy = '10個月';
      }
      if (days >= 28 && days < 56) {
        user.pregnancy = '9個月';
      }
      if (days >= 56 && days < 84) {
        user.pregnancy = '8個月';
      }
      if (days >= 84 && days < 112) {
        user.pregnancy = '7個月';
      }
      if (days >= 112 && days < 140) {
        user.pregnancy = '6個月';
      }
      if (days >= 140 && days < 168) {
        user.pregnancy = '5個月';
      }
      if (days >= 168 && days < 196) {
        user.pregnancy = '4個月';
      }
      if (days >= 196 && days < 224) {
        user.pregnancy = '3個月';
      }
      if (days >= 224 && days < 252) {
        user.pregnancy = '2個月';
      }
      if (days >= 252 && days < 280) {
        user.pregnancy = '1個月';
      }
    } else {
      if (user.lmp_date) {
        const lmp_date = parse(user.lmp_date, 'YYYY/MM/DD', new Date());
        const days = differenceInDays(lmp_date, today);

        user.pregnancy = '';
        if (days >= 0 && days < 28) {
          user.pregnancy = '1個月';
        }
        if (days >= 28 && days < 56) {
          user.pregnancy = '2個月';
        }
        if (days >= 56 && days < 84) {
          user.pregnancy = '3個月';
        }
        if (days >= 84 && days < 112) {
          user.pregnancy = '4個月';
        }
        if (days >= 112 && days < 140) {
          user.pregnancy = '5個月';
        }
        if (days >= 140 && days < 168) {
          user.pregnancy = '6個月';
        }
        if (days >= 168 && days < 196) {
          user.pregnancy = '7個月';
        }
        if (days >= 196 && days < 224) {
          user.pregnancy = '8個月';
        }
        if (days >= 224 && days < 252) {
          user.pregnancy = '9個月';
        }
        if (days >= 252 && days < 280) {
          user.pregnancy = '10個月';
        }
      } else {
        console.log('no info to pregnancy');
      }
    }
    console.log('map_user_object return', user);
    return user;
  }

  get_zip_code(data?: any): Promise<any> {
    let endpoint = 'mappv1/zip_code';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'sel';
      params.data = data;

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  private extractData(res) {
    console.log(res);
    let body = res; //httpclient , res已經是json
    console.log(body);
    if (body.returnCode === 'OK') {
      return body;
    } else {
      // throw '' + body.message;
      throw { api_error: true, message: body.message };
    }
  }

  // private handleError(error: any) {
  //   console.log(error);
  //   return Observable.throw(error);
  // }

  /*****************************************************************/
  /*  以下為 refactor */
  /*****************************************************************/
  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  setUser(user: any) {
    this.userId = user.uid;
    this.isLogined = true;
    this._isAuthenticated.next(true);
  }

  // 登出 user
  // 1. eline logout
  // 2. fb_logout
  // 3. logout post actions (ex: unsubscribe xxx , remove localstorage...)
  logoutUser() {
    console.log('logoutUser');

    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        params.device_id = localStorage.device_id; //增加device_id,以利firebase topic unsubscribe

        try {
          const logout_result = await this.logout(params);
          console.log('eline logout done.', logout_result);
        } catch (error) {
          console.error('eline logout error:', error);
          reject(error);
          return;
        }

        try {
          const fb_logout_result = await this.fb_logout();
          console.log('fb_logout_result done', fb_logout_result);
        } catch (error) {
          console.error('fb_logout_result error:', error);
          reject(error);
          return;
        }

        localStorage.clear();
        await this.storage.remove('user');
        await this.storage.remove('auth_token');
        await this.storage.remove('localstorage');
        await this.storage.clear();

        try {
          (<any>window).FirebasePlugin.unregister();
          console.log('fcm.unregister success..');
        } catch (error) {
          console.log('fcm.unregister failed..', error);
        }

        resolve('OK');
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }

  // 1. 載入 local auth_token
  // 2. verify auth_token
  //        |- success -> step 3
  //        |- failed -> app user not authenticated -> welcome page
  //        |- network failed -> step 3
  // 3. 載入 firebase auth state
  //   3.1 not authenticated -> refresh firebase custom token
  //                                  |- success -> login firebase
  //                                  |- failed -> app user not authenticated -> welcome page
  //   3.2 authenticated -> app user authenticated
  // initAuthStateMonitor() {
  //   console.warn('initAuthStateMonitor');

  //   return new Promise(async (resolve, reject) => {
  //     // 1. 載入 local auth_token
  //     const auth_token = await this.storage.get('auth_token');

  //     if (auth_token) {
  //       // dispatch local authenticated event -> redirect to tabs/inquire page
  //       // 調整為不實作 LocalAppUserAuthenticated 狀態
  //       // this.store.dispatch(new LocalAppUserAuthenticated());

  //       // 檢查 token 狀態 OK, REFRESH , EXPIRE , ERROR
  //       const token_status = this.check_jwt(auth_token);
  //       const ok_statuses = ['OK', 'REFRESH'];
  //       console.log('token_status', token_status);

  //       if (ok_statuses.includes(token_status)) {
  //         // 檢查token後若需執行 refresh token , 則執行
  //         if (token_status === 'REFRESH') {
  //           try {
  //             await this.store.dispatch(new RefreshToken()).toPromise();
  //           } catch (error) {
  //             console.error('refresh token error', error);
  //           }
  //         }

  //         // 2. verify eline auth token, 會忽略網路問題, 若出現api error, 代表權限被收回了
  //         try {
  //           const result = await this.verify_token();
  //           console.log('verify_token done: ', result);
  //         } catch (error) {
  //           console.error('verify_token error,', error);
  //           this.store.dispatch(new AppUserNotAuthenticated());
  //           resolve('DONE');
  //           return;
  //         }

  //         // 3. 載入 firebase auth state
  //         try {
  //           this.afAuth.onAuthStateChanged(async (fbuser) => {
  //             if (fbuser) {
  //               console.log('fb user authenticated..', fbuser);
  //               // 3.2 authenticated -> app user authenticated
  //               this.store.dispatch(new AppUserAuthenticated());
  //               resolve(true);
  //             } else {
  //               console.log('fb user not authenticated..');
  //               const userState = this.store.selectSnapshot(
  //                 (state) => state.UserState
  //               );
  //               const user: ElineUser = userState.user;
  //               const logout_flag = userState.logoutFlag;

  //               if (logout_flag === false) {
  //                 if (user) {
  //                   console.log('有user資料,但fb authenticated failed..');

  //                   try {
  //                     // 3.1 not authenticated -> refresh firebase custom token
  //                     console.warn('refresh fb custom token!!');
  //                     const firebase_custom_token =
  //                       await this.refresh_fb_custom_token(user.user_id);
  //                     console.log(
  //                       'refresh_fb_custom_token done',
  //                       firebase_custom_token
  //                     );
  //                     await this.fb_login(firebase_custom_token);
  //                     console.log('fb_login done');
  //                     // this.store.dispatch(new AppUserAuthenticated());
  //                     resolve('APP_USER_AUTHENTICATED');
  //                   } catch (error) {
  //                     console.error(error);
  //                     console.warn('refresh_fb_custom_token failed');
  //                     console.warn('您的認證資訊已過期,請重新登入,謝謝!');
  //                     this.store.dispatch(new AppUserNotAuthenticated());
  //                     reject('APP_USER_NOT_AUTHENTICATED');
  //                   }
  //                 } else {
  //                   console.error(
  //                     '有auth_token, 但沒有 user object, 應該不會發生'
  //                   );
  //                   this.store.dispatch(new AppUserNotAuthenticated());
  //                   reject('unknow error');
  //                 }
  //               } else {
  //                 console.log('user logout..');
  //                 // do nothing, 有監聽 LOGOUT ACTION 的 code
  //                 resolve('DONE');
  //               }
  //             }
  //           });
  //         } catch (error) {
  //           console.error('afAuth.onAuthStateChanged error:', error);
  //           resolve('DONE');
  //         }
  //       } else {
  //         console.log('token is not validate, not authenticated');
  //         this.store.dispatch(new AppUserNotAuthenticated());
  //         resolve('DONE');
  //       }
  //     } else {
  //       console.log('no auth_token, not authenticated');
  //       this.store.dispatch(new AppUserNotAuthenticated());
  //       resolve('DONE');
  //     }

  //     // this.afAuth.onIdTokenChanged(async user => {
  //     //   if (user) {
  //     //     this.idToken = await user.getIdToken(true);
  //     //     console.log('this.idToken', this.idToken);
  //     //     window.localStorage.setItem('idToken', this.idToken);
  //     //   } else {
  //     //     console.log('user is null');
  //     //   }
  //     // });
  //   });
  // }

  // from server抓取 user 相關資訊
  syncUserMe() {
    console.log('syncUserMe');

    return new Promise(async (resolve, reject) => {
      let result;
      try {
        result = await this.get_user_me();
        let user = result.data;
        if (user.account_type === null) {
          user.account_type = 'duty';
        }

        //整理家人模式
        user = this.map_user_object(user);

        this.storage.set('user', user);
        resolve(user);
      } catch (error) {
        console.error('get_user_me4 error', error);
        reject(error);
        return;
      }
    });
  }

  // 個案端 email 登入
  email_login(params, loader?) {
    console.log('email_login', params);

    return new Promise(async (resolve, reject) => {
      // step1: login 取得 eline token & firebase custom token
      // step2: get user me4
      // step3: login firebase

      let login_result;
      let auth_token;
      let firebase_custom_token;
      try {
        login_result = await this.login2(params);
        console.log('login2 success', login_result);

        auth_token = login_result.data['auth-token'];
        firebase_custom_token = login_result.data.firebase_token;
        await this.storage.set('auth_token', auth_token);
      } catch (error) {
        console.error('login error', error);
        reject(error);
        return;
      }

      if (loader) {
        loader.setContent('登入中,請再稍後!');
      }

      // step 2
      let user_me_result;
      let user;

      try {
        user_me_result = await this.get_user_me(auth_token);
        console.log('get user me object success..', user_me_result);
        user = user_me_result.data;
      } catch (error) {
        console.error('get_user_me4 error', error);
        await this.storage.remove('auth_token');
        reject(error);
        return;
      }

      // step 3
      //進行firebase login作業
      let fb_login_result;

      try {
        fb_login_result = await this.fb_login(firebase_custom_token);
        console.log('fb_login success..');
        console.log('user authenticate success, start app...');

        await this.storage.set('auth_token', auth_token);
        await this.storage.set('firebase_custom_token', firebase_custom_token);

        //整理家人模式
        user = this.map_user_object(user);
        await this.storage.set('user', user);

        resolve(user);
      } catch (error) {
        console.error('fb_login error,', error);
        await this.storage.remove('auth_token');
        reject(error);
        return;
      }
    });
  }

  // 個案端, phone 登入, 跟 phone_signup 程序大部分依樣, 跳過 signup2 api
  phone_login(params, loader?) {
    console.log('phone_login');
    return this.phone_signup(params, loader, true);
  }

  // 個案端, email 註冊
  email_signup(params, loader?) {
    console.log('email_signup');

    return new Promise(async (resolve, reject) => {
      // step1: signup2
      // step2: login2
      // step3: get_user_me4
      // step4: fb_login

      //step1: signup2

      let signup2_result;
      try {
        signup2_result = await this.signup2(params);
        console.log('signup success...');
      } catch (error) {
        console.error('signup2 error', error);
        reject(error);
        return;
      }

      if (loader) {
        loader.setContent('登入中,請稍後!');
      }

      // step2: login2 , 註冊成功,執行登入作業
      let login2_result;
      let auth_token;
      let firebase_custom_token;
      try {
        login2_result = await this.login2(params);
        console.log('login2 success', login2_result);

        auth_token = login2_result.data['auth-token'];
        firebase_custom_token = login2_result.data.firebase_token;
        await this.storage.set('auth_token', auth_token);
      } catch (error) {
        console.error('login error', error);
        reject(error);
        return;
      }

      if (loader) {
        loader.setContent('登入中,請再稍後!');
      }

      // step 3
      let user_me_result;
      let user;

      try {
        user_me_result = await this.get_user_me(auth_token);
        console.log('get user me object success..', user_me_result);
        user = user_me_result.data;
      } catch (error) {
        console.error('get_user_me4 error', error);
        await this.storage.remove('auth_token');
        reject(error);
        return;
      }

      // step 4
      //進行firebase login作業
      let fb_login_result;

      try {
        fb_login_result = await this.fb_login(firebase_custom_token);
        console.log('fb_login success..');
        console.log('user authenticate success, start app...');

        await this.storage.set('auth_token', auth_token);
        await this.storage.set('firebase_custom_token', firebase_custom_token);

        //整理家人模式
        user = this.map_user_object(user);
        await this.storage.set('user', user);

        resolve(user);
      } catch (error) {
        console.error('fb_login error,', error);
        await this.storage.remove('auth_token');
        reject(error);
        return;
      }
    });
  }

  // 個案端, phone 註冊
  phone_signup(params, loader?, login_flag?) {
    console.log('phone_signup');

    return new Promise(async (resolve, reject) => {
      // step1: fb_login2(credential)
      // step2: 註冊手機帳號到eline (login不需要)
      // step3: eline oauth
      // step4: get_user_me4

      //step1: fb_login2

      let fb_login2_result;
      try {
        fb_login2_result = await this.fb_login2(params.credential);
        console.log('firebase phone sms code驗證成功..');
        console.log('fb_login2_result success...');
      } catch (error) {
        console.error('fb_login2_result error', error);
        reject(error);
        return;
      }

      if (loader) {
        loader.setContent('帳號登入中,請稍後!');
      }

      if (!login_flag) {
        console.log('帳號門號註冊');
        // 註冊帳號到eline
        let signup2_result;
        try {
          let signup2_params: any = {};
          signup2_params.phone = params.phone;
          signup2_params.account_type = params.account_type;
          signup2_params.security_question = params.security_question;
          signup2_result = await this.signup2(signup2_params);
          console.log('signup2 success...', signup2_result);
          if (loader) {
            loader.setContent('帳號登入中,請稍後!');
          }
        } catch (error) {
          console.error('signup2 error', error);
          reject(error);
          return;
        }
      }

      // step3: eline oauth

      //取得firebase idToken,並回傳server讓server驗證
      let idToken;
      try {
        idToken = (await this.afAuth.currentUser).getIdToken(true);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const user = await this.afAuth.currentUser;
        idToken = await user.getIdToken(true);
        console.log('idToken', idToken);
      } catch (error) {
        console.error('get idToken error', error);
        reject(error);
        return;
      }

      let oauth_result;
      let auth_token;

      let new_params: any = {};
      new_params.phone = params.phone;
      new_params.firebase_access_token = idToken;
      new_params.account_type = params.account_type;
      new_params.device_id = params.device_id;
      console.log('new_params', new_params);
      console.log('new_params', JSON.stringify(new_params));

      try {
        oauth_result = await this.oauth(new_params);
        console.log('oauth success', oauth_result);

        auth_token = oauth_result.data['auth-token'];
        await this.storage.set('auth_token', auth_token);
      } catch (error) {
        console.error('oauth error', error);
        reject(error);
        return;
      }

      if (loader) {
        loader.setContent('登入中,請再稍後!');
      }

      // step 4
      let user_me_result;
      let user;

      try {
        user_me_result = await this.get_user_me(auth_token);
        console.log('get user me object success..', user_me_result);
        user = user_me_result.data;

        await this.storage.set('auth_token', auth_token);

        //整理家人模式
        user = this.map_user_object(user);
        await this.storage.set('user', user);

        resolve(user);
      } catch (error) {
        console.error('get_user_me4 error', error);
        await this.storage.remove('auth_token');
        reject(error);
        return;
      }
    });
  }

  // 變更使用者的密碼
  user_change_password(params) {
    return new Promise(async (resolve, reject) => {
      try {
        let update_result = await this.change_password(params);
        console.log('change_password done', update_result);

        const auth_token = update_result.data['auth-token'];
        const firebase_token = update_result.data.firebase_token;

        //change password會更新firebase token,所以要重新login firebase
        //進行firebase login作業

        let fb_login_result;

        try {
          fb_login_result = await this.fb_login(firebase_token);
          console.log('fb_login success..');
          this.storage.set('auth_token', auth_token);
          this.storage.set('firebase_custom_token', firebase_token);

          resolve('OK');
        } catch (error) {
          console.error('fb_login error:', error);
          reject(error);
          return;
        }
      } catch (error) {
        console.error('change_password error:', error);
        reject(error);
        return;
      }
    });
  }

  user_notification_setting_change(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const update_result: any = await this.update_user_me(params);
        console.log('update_user_me done', update_result);
        let user = update_result.data;
        user.account_type = 'duty';

        await this.storage.set('user', user);
        resolve('OK');
      } catch (error) {
        console.error('update_user_me error', error);
        reject(error);
      }
    });
  }

  update_user_profile(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const update_result: any = await this.update_user_me(params);
        console.log('update_user_me done', update_result);
        let user = update_result.data;
        user.account_type = 'duty';

        await this.storage.set('user', user);
        resolve('OK');
      } catch (error) {
        console.error('update_user_me error', error);
        reject(error);
      }
    });
  }

  // verify eline token是否還有效?
  verify_token() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.verifyToken();
        resolve('OK');
      } catch (error) {
        console.error('verifyToken error,', error);
        if (error.api_error) {
          reject(error);
        } else {
          console.log('network error,', error);
          console.log('ignore check token..');
          resolve('IGNORE');
        }
      }
    });
  }

  // app.resume時, 驗證 eline token是否還有效? 如果無效, 要登出
  // check_token() {
  //   console.log('check_token');

  //   return new Promise((resolve, reject) => {
  //     this.verify_token()
  //       .then((resp) => {
  //         console.log('verify_token success!');
  //         resolve('OK');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         if (err.api_error) {
  //           console.warn('您的醫LINE認證資訊已過期,請重新登入,謝謝');
  //           this.store.dispatch(new AppUserNotAuthenticated());
  //           resolve('Done');
  //         } else {
  //           console.log('network error,' + err);
  //           console.log('ignore check token..');
  //           resolve('Done');
  //         }
  //       });
  //   });
  // }

  // work-around, 暫時將 channel_id security rule set to free
  update_channel_id_to_free(channel_id: string): Promise<any> {
    let endpoint = 'appv1/update_channel_id_to_free';

    return new Promise(async (resolve, reject) => {
      let params: any = {};
      params.method = 'upd';
      params.data = { channel_id };

      try {
        const result = await this.api.post(endpoint, params);
        resolve(this.extractData(result));
      } catch (error) {
        reject(error);
      }
    });
  }

  refresh_token() {
    return new Promise(async (resolve, reject) => {
      try {
        let params: any = {};
        // device資訊會儲存在 jwt payload, 不需要自行帶入
        // params.device_id = localStorage.getItem('device_id');
        // params.device_name = localStorage.getItem('device_name');
        let result = await this.refreshToken(params);
        console.log('refreshToken result', result);
        await this.storage.set('auth_token', result.data['auth-token']);
        resolve('OK');
      } catch (error) {
        console.error('refreshToken error,', error);
        if (error.api_error) {
          reject(error);
        } else {
          console.log('network error,', error);
          console.log('ignore check token..');
          resolve('IGNORE');
        }
      }
    });
  }

  // decode jwt token, 若過期則登出,重新登入
  // 若距離過期日 < 180 則 refresh_token 展延
  // 回傳: OK ,
  //      REFRESH: 代表需要 refresh token ,
  //      EXPIRE: 表示過期, 可以redirect to login page
  //      ERROR: 其他錯誤
  check_jwt(token): string {
    try {
      if (token) {
        // decode jwt token, 若過期則登出,重新登入
        // 若距離過期日 < 180 則 refresh_token 展延
        const decoded: any = jwt_decode(token);
        console.log('jwt decoded', decoded);
        const expire_date = fromUnixTime(decoded.exp);
        const expire_date_desc = format(
          fromUnixTime(decoded.exp),
          'YYYY-MM-DD HH:mm:ss'
        );
        // console.log('expire_date', expire_date);
        console.log('expire_date_desc', expire_date_desc);
        const now = new Date();
        // const duration = moment.duration(expire_date.diff(now));
        // console.log('jwt exp_date duration hours:', duration.get('hours'));
        const duration_days = differenceInDays(expire_date, now);
        console.log('jwt exp_date duration days:', duration_days);

        if (duration_days >= 0 && duration_days < 180) {
          return 'REFRESH';
        } else if (duration_days >= 180) {
          return 'OK';
        } else if (duration_days < 0) {
          return 'EXPIRE';
        } else {
          console.error('unknow duration_days', duration_days);
          return 'ERROR';
        }
      } else {
        console.error('check_jwt error, token is null');
        return 'ERROR';
      }
    } catch (error) {
      console.error('check_jwt error', error);
      return 'ERROR';
    }
  }
}
