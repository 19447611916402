import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TrashObject } from '@mommy/models/Comm.model';
import { OthersService } from '@mommy/services/others/others.service';
import { StorageService } from '@mommy/services/storage.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as _ from 'lodash';
import {
  InitGraphqlData,
  InitLocalCacheTrashObjects,
  LoadCacheTrashObjects,
  RefreshTrashObjects,
} from './app-init.actions';

// *** 不可以 import 其他 State, 以免circle import ***
export interface AppInitStateModel {
  trash_objects: TrashObject[];
}

const defaultAppInitState = (): AppInitStateModel => {
  return {
    trash_objects: [],
  };
};

@State<AppInitStateModel>({
  name: 'AppInitState',
  defaults: defaultAppInitState(),
})
@Injectable()
export class AppInitState {
  private _isServer: boolean;

  constructor(
    private storage: StorageService,
    private store: Store,
    private othersService: OthersService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this._isServer = isPlatformServer(this.platformId);
  }

  @Selector([AppInitState])
  static _trash_objects(state: AppInitStateModel) {
    return state.trash_objects;
  }

  @Selector([AppInitState._trash_objects])
  static hospital_trash_objects(trash_objects: TrashObject[]) {
    return _.filter(trash_objects, (item: TrashObject) => {
      return item.trash_type === 'HOSPITAL';
    });
  }

  @Selector([AppInitState._trash_objects])
  static expert_trash_objects(trash_objects: TrashObject[]) {
    return _.filter(trash_objects, (item: TrashObject) => {
      return item.trash_type === 'EXPERT';
    });
  }

  @Selector([AppInitState._trash_objects])
  static post_trash_objects(trash_objects: TrashObject[]) {
    return _.filter(trash_objects, (item: TrashObject) => {
      return item.trash_type === 'POST';
    });
  }

  @Selector([AppInitState._trash_objects])
  static vote_trash_objects(trash_objects: TrashObject[]) {
    return _.filter(trash_objects, (item: TrashObject) => {
      return item.trash_type === 'VOTE';
    });
  }

  //#region ***** Action *****
  @Action(InitLocalCacheTrashObjects)
  async InitLocalCacheTrashObjects(ctx: StateContext<AppInitStateModel>) {
    console.log('[Action] InitLocalCacheTrashObjects');

    // 策略調整為：
    // 1. 先載入 local cache，如果沒有就抓 server (full)
    // 2. 載入完 cache 再抓 server 更新的資料 (incremental)
    try {
      await ctx.dispatch(new LoadCacheTrashObjects()).toPromise();
      console.log('load local cache trashobjects success');
      // 再呼叫 getExpertsFromServer 來更新 local cache
      await ctx.dispatch(new RefreshTrashObjects()).toPromise();
    } catch (error) {
      console.warn('LoadCacheExperts error', error);
      // 如果沒有 cache, 就去 server 取
      await ctx.dispatch(new RefreshTrashObjects()).toPromise();
    }
  }

  @Action(LoadCacheTrashObjects)
  async LoadCacheTrashObjects(ctx: StateContext<AppInitStateModel>) {
    console.log('[Action] LoadCacheTrashObjects');

    const state = ctx.getState();
    const _trashObjects: any = await this.storage.get('trash-objects');

    if (_trashObjects) {
      ctx.patchState({
        trash_objects: _trashObjects,
      });
    } else {
      throw new Error('no cache');
    }
  }

  @Action(RefreshTrashObjects)
  async RefreshTrashObjects(ctx: StateContext<AppInitStateModel>) {
    console.log('[Action] RefreshTrashObjects');
    const state = ctx.getState();

    try {
      let trashObjects: any;
      if (this._isServer) {
        trashObjects = await this.othersService.getTrashObjectsAtServer();
      } else {
        trashObjects = await this.othersService.getTrashObjects();
      }
      console.log('trashObjects', trashObjects);
      await this.storage.set('trash-objects', trashObjects);

      ctx.patchState({
        trash_objects: trashObjects,
      });
    } catch (error2) {
      console.error('getTrashObjects error', error2);
    }
  }

  @Action(InitGraphqlData)
  async InitGraphqlData(ctx: StateContext<AppInitStateModel>) {
    console.log('[Action] InitGraphqlData');
    const state = ctx.getState();

    try {
      let result: any;
      if (this._isServer) {
        result = await this.othersService.getGraphQLDataAtServer();
      } else {
        result = await this.othersService.getGraphQLData();
      }
      console.log('getGraphQLData result', result);
    } catch (error2) {
      console.error('getGraphQLData error', error2);
    }
  }
  //#endregion
}
