export class AppSettings {
  // 定義 cache key
  public static CACHE_KEY_HOSPITAL_LIST = 'hospital_list';
  public static CACHE_KEY_EXPERT_LIST = 'expert_list';
  public static CACHE_KEY_POST_LIST = 'post_list';
  public static CACHE_KEY_VOTE_LIST = 'vote_list';
  public static CACHE_KEY_PGC_POST_SCORE_LIST = 'pgc_post_score_list';
  public static HospitalBadgeDict: any = {
    學苑推薦: 'assets/img/Badge01.png',
    孕關懷特約診所: 'assets/img/Badge04.png',
    人氣王: 'assets/img/Badge02.png',
    高CP值: 'assets/img/Badge06.png',
    頂級奢華: 'assets/img/Badge05.png',
  };
  public static AUTO_REPLY_DISPLAY_NAME = '智慧助理';
  public static APP_NAME = '孕關懷';
  public static APP_CODE = 'patient';
  public static APP_VERSION_CODE = '3.0.20';
  public static DISABLE_AD = true; // 是否關閉廣告
  public static POSTS_PER_PAGE = 20; // 育兒知識 呼叫 wp-api 每頁撈取的筆數

  public static SEO_DEFAULT_TITLE =
    '媽咪學苑-你的專業知識庫，陪你優雅安心當媽咪!';
  public static SEO_DEFAULT_DESCRIPTION =
    '我們致力於提供有邏輯的解決方案給正處於孕、產、哺、育階段的家庭，我們團隊由醫師藥師護理師營養師組成，以專業為出發點，打造孕產育一站式服務母嬰平台，讓媽咪安心生、快樂養!';
  public static SEO_DEFAULT_SUFFIX = '|媽咪學苑APP';

  public static URL_ABOUT = 'https://www.mommycare.cc/about/';

  // 聯絡我們
  public static URL_CONTACT = 'https://www.mommycare.cc/contact/';

  // 意見回饋
  public static URL_FEEDBACK = 'https://appinfo.mommycareyou.com/feedback';

  // 隱私權政策
  public static URL_PRIVACY =
    'https://appinfo.mommycareyou.com/mcuapp/private.html#private';

  // 服務條款
  public static URL_TERMS =
    'https://appinfo.mommycareyou.com/mcuapp/private.html#service';

  // 電子商務約定條款
  public static URL_EC_TERMS =
    'https://appinfo.mommycareyou.com/mcuapp/ecservice.html';

  // 關於退貨退款
  public static URL_EC_RETURN_TERMS =
    'https://appinfo.mommycareyou.com/mcuapp/ecservice.html#return';

  // ISR cache 的時效, 區分高、中、低
  // 內容相關的 cache, 以及不會變動的 cache, 可以設定較長的時效 (ex: 文章, 診所, 專家)
  // EC 相關的 cache, 以及會變動的 cache, 設定較短的時效 (ex: 訂單, 購物車)
  // 行為相關的 cache, 設定 中 的時效 (ex: 登入, 註冊..)
  public static ISR_CACHE_EXPIRE_HIGH = 600; // 600秒
  public static ISR_CACHE_EXPIRE_MEDIUM = 60; // 60秒
  public static ISR_CACHE_EXPIRE_LOW = 10; // 10秒
}
