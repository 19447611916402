import { Injectable } from '@angular/core';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { FCM } from '@capacitor-community/fcm';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { ElineUser } from '@mommy/models/Eline.model';
import { ElineUserService } from '@mommy/services/eline/eline-user-service';
import { StorageService } from '@mommy/services/storage.service';
import { AppSettings } from 'app/app.settings';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { CapUtilService } from './caputil.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  onnotificationopenSubscription: Subscription;
  appstatus = 'active';
  firebase_push_token;

  constructor(
    private caputil: CapUtilService,
    private elineUserService: ElineUserService,
    private storage: StorageService,
    // private firebaseX: FirebaseX,
    private platform: Platform
  ) {
    console.log('Hello PushNotificationService');

    this.platform.resume.subscribe(() => {
      this.appstatus = 'resume';
    });

    this.platform.pause.subscribe(() => {
      this.appstatus = 'pause';
    });
  }

  // User登入後要呼叫
  // User異動channel後要呼叫(ex: 增加諮詢醫療團隊, 家人模式調整)
  set_firebase_notify(user: ElineUser) {
    console.log('set_firebase_notify', user);

    return new Promise(async (resolve, reject) => {
      let enable = true;
      if (user.enable_notify) {
        if (user.enable_notify === 'Y') {
          enable = true;
        } else {
          enable = false;
        }
      } else {
        enable = true; //undefined, set to true
      }

      console.log(`set_firebase_notify to ${enable}`);

      if (this.platform.is('cordova') || this.platform.is('capacitor')) {
        if (enable === true) {
          try {
            // subscribe 自己
            await FCM.subscribeTo({ topic: 'u' + user.user_id });
            console.log('fcm subscribe topics:u' + user.user_id + ' done!');

            // for 家人模式調整 by channel_id
            console.log('user.family_channels', user.family_channels);
            _.forEach(user.family_channels, async (elem) => {
              await FCM.subscribeTo({ topic: elem });
              console.log('fcm subscribe topics:' + elem + ' done!');
            });
            resolve('OK');
          } catch (error) {
            reject(error);
            return;
          }
        } else if (enable === false) {
          try {
            // unsubscribe 自己
            await FCM.unsubscribeFrom({ topic: 'u' + user.user_id });
            console.log('fcm unsubscribe topics:u' + user.user_id + ' done!');

            // for 家人模式調整 by channel_id
            console.log('user.family_channels', user.family_channels);
            _.forEach(user.family_channels, async (elem) => {
              await FCM.unsubscribeFrom({ topic: elem });
              console.log('fcm unsubscribe topics:' + elem + ' done!');
            });
            resolve('OK');
          } catch (error) {
            console.log(error);
            reject(error);
          }
        }
      } else {
        // 如果不是 cordova, 都回OK
        console.warn('not cordova, alwarys return OK!');
        resolve('OK');
      }
    });
  }

  fcm_unsubscribe(topic) {
    console.log('fcm_unsubscribe', topic);

    return new Promise(async (resolve, reject) => {
      // try {
      //   (<any>window).FirebasePlugin.unsubscribe(
      //     topic,
      //     () => {
      //       console.log('fcm unsubscribe topics:' + topic + ' done!');
      //       resolve('OK');
      //     },
      //     (error) => {
      //       console.log(error);
      //       console.log('fcm unsubscribe topics:' + topic + ' error!');
      //       reject(error);
      //     }
      //   );
      // } catch (error) {
      //   console.log(error);
      //   console.log('fcm unsubscribe topics:' + topic + ' error!');
      //   reject(error);
      // }

      try {
        await FCM.unsubscribeFrom({ topic: topic });
        console.log('fcm unsubscribe topics:' + topic + ' done!');
        resolve('OK');
      } catch (error) {
        console.log(error);
        console.log('fcm unsubscribe topics:' + topic + ' error!');
        reject(error);
      }
    });
  }

  // User登出後要呼叫
  // PS: 會導致 裝置收不到活動通知 or 門號認證失效?
  // 再次呼叫 init_push_notification 可以解決?
  fcm_deleteInstance() {
    console.log('fcm_deleteInstance');
    return FCM.deleteInstance();
  }

  // init_push_plugin() {
  //   console.log('init_push_plugin');

  //   if (this.platform.is('cordova') === true) {
  //     try {
  //       // push notification register
  //       this.firebaseX
  //         .getToken()
  //         .then(async (token) => {
  //           console.log(`The token is ${token}`);
  //           await this.storage.set('push_token', token);
  //           this.firebase_push_token = token;
  //           this.register_device(token);
  //         })
  //         .catch((error) => {
  //           console.warn('Error getting token', error);
  //         });

  //       this.firebaseX.onTokenRefresh().subscribe(
  //         async (token) => {
  //           console.log(`Got a new token ${token}`);
  //           await this.storage.set('push_token', token);
  //           this.firebase_push_token = token;
  //           this.register_device(token);
  //         },
  //         (error) => {
  //           console.log('Error getting new token', error);
  //         }
  //       );

  //       //server會送兩則推撥,第一則是data message, 會callback這個function,data.tap會是false
  //       //                  第二則是push message, 也會callback這個function,data.tap會是false
  //       // 當user click notification(會有notification表示app在backgound), 也會callback這個function,data.tap = true
  //       try {
  //         this.onnotificationopenSubscription.unsubscribe();
  //       } catch (error) {
  //         console.log(error);
  //       }

  //       try {
  //         this.firebaseX.onMessageReceived().subscribe((data) => {
  //           console.log(data);
  //           console.log('click notification : ', data.tap); // data.tap = 'background' , 在背景click notification時的回傳值

  //           const msg_id = data.msg_id;
  //           console.log('msg_id', msg_id);
  //           const msg_type = data.type;
  //           console.log('msg_type', msg_type);
  //           console.log('appstatus', this.appstatus);

  //           if (this.appstatus === 'pause' && msg_id !== undefined) {
  //             console.log('app in background!');

  //             // TODO: 在 background retrieve data 暫時先pending, 有好的想法再實作

  //             // if (localStorage.last_msg_id === msg_id) {
  //             //   console.log('已經處理過此msg_id', msg_id);
  //             // } else {
  //             //   console.log('last_msg_id=,', localStorage.last_msg_id);
  //             //   console.log('尚未處理過此msg_id', msg_id);

  //             //   if (msg_type == 'msg') {
  //             //     // 不行call init_channels, 因為foreground要持續的監控資料的變化
  //             //     // 但是如果是background,則只須要讀取一次就可以
  //             //     console.log('call get_channels_once');
  //             //     console.log('fb.database().goOnline()');
  //             //     fb.database().goOnline();

  //             //     this.get_channels_once()
  //             //       .then(() => {
  //             //         localStorage.last_msg_id = msg_id;
  //             //         console.log('get_channels_once done.');
  //             //         // console.log('fb.database().goOffline()');
  //             //         // fb.database().goOffline();
  //             //       })
  //             //       .catch(err => {
  //             //         console.log('get_channels_once failed', err);
  //             //         // console.log('fb.database().goOffline()');
  //             //         // fb.database().goOffline();
  //             //       });
  //             //   } else if (msg_type == 'issue') {
  //             //     // 不行call init_channels, 因為foreground要持續的監控資料的變化
  //             //     // 但是如果是background,則只須要讀取一次就可以
  //             //     console.log('call get_issues_once');
  //             //     console.log('fb.database().goOnline()');
  //             //     fb.database().goOnline();

  //             //     this.get_issues_once()
  //             //       .then(() => {
  //             //         localStorage.last_msg_id = msg_id;
  //             //         console.log('get_issues_once done.');
  //             //         // console.log('fb.database().goOffline()');
  //             //         // fb.database().goOffline();
  //             //       })
  //             //       .catch(err => {
  //             //         console.log('get_issues_once failed', err);
  //             //         // console.log('fb.database().goOffline()');
  //             //         // fb.database().goOffline();
  //             //       });
  //             //   } else {
  //             //     console.log('unknow msg_type');
  //             //   }
  //             // }
  //           } else {
  //             console.log(
  //               'app in foreground or msg_id = undefined ! skip process message procedure.'
  //             );
  //           }
  //         });

  //         this.firebaseX.hasPermission().then((hasPermission) => {
  //           console.log('fcm.hasPermission:', hasPermission);
  //         });

  //         this.firebaseX
  //           .grantPermission()
  //           .then((res) => {
  //             console.log('fcm.grantPermission():', res);
  //           })
  //           .catch((err) => {
  //             console.error('fcm.grantPermission failed,', err);
  //           });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     console.log('not cordova..');
  //   }
  // }

  async init_push_notification() {
    console.log('init_push_notification @PushNotificationService');

    await PushNotifications.removeAllListeners();
    PushNotifications.requestPermissions().then((result) => {
      console.log('requestPermissions result', result);
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.error('requestPermissions failed');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      //alert('Push registration success, token: ' + token.value);
      // EX: (not jwt format) c7eF1TWhAkfCpLxAYclxkz:APA91bHhbnQikJFI689aVw5m7kvcLJGJ0YYxWKwN-6mpmYwMTUspU75IA8GyJ8UTcyAR6PlKLqD9OPwItGYxcPsvFOYTdGXGkt7m836DXoOKWG3GAXpdnpU25Yvptp0dtNy8p65lacm-
      console.log('Push registration success, token: ' + token.value);

      this.register_device(token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    // Get FCM token instead the APN one returned by Capacitor
    // android 取得的 token 格式(jwt)怪怪的, 無法透過 fcm/send api 發送訊息 (InvalidRegistration)
    // ios 取得的 token , 跟上面 registration callback 取得的 token 格式一樣
    // 故暫時以上面的 registration callback 取得的 token 為主
    FCM.getToken()
      .then((result) => {
        console.log(`firebase push Token ${result.token}`);

        // // 如果有登入, 則把token傳給server
        // this.register_device(result.token);
      })
      .catch((err) => console.error('FCM.getToken error', err));

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        console.log('Push action performed: ' + JSON.stringify(notification));
        // TODO: 處理notification, 例如開啟某個頁面
      }
    );

    // FCM.subscribeTo({ topic: 'u759' })
    //   .then((r) => alert(`subscribed to topic u759 success`))
    //   .catch((err) => console.log(err));
  }

  register_device(firebase_push_token) {
    console.log('register_device', firebase_push_token);

    this.caputil
      .get_device_info()
      .then(async () => {
        const data: any = {};
        data.device_id = localStorage.device_id;
        data.device_name = localStorage.device_name;
        data.device_data = localStorage.device_data;
        data.device_platform = localStorage.device_platform;
        data.firebase_push_token = firebase_push_token;
        data.app_name = AppSettings.APP_CODE;

        //若沒有auth-token,則ignore
        const token = await this.storage.get('eline_token');

        if (token) {
          this.elineUserService
            .register_device2(data)
            .then((res) => {
              console.log('register_device done.');
              console.log(res);
            })
            .catch((err) => {
              console.log('register_device done.');
              console.log(err);
            });
        } else {
          console.log('not auth-token , ignore..');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
