import { Injectable } from '@angular/core';
import { APIResponse } from '@mommy/models/Comm.model';
import { QueryCommodityParams } from '@mommy/models/ec/ECCommon.model';
import { ECApi } from './ec-api';
const fs = require('fs');

@Injectable({
  providedIn: 'root',
})
export class ECCommodityService {
  constructor(private api: ECApi) {
    console.log('Hello ECCommodityService');
  }

  getCommodityCategory(): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityCategoryViews`;

    return new Promise(async (resolve, reject) => {
      try {
        const params: any = {};
        params.id = 0; // 列出全部

        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCommodityCategory error', error);
        reject(error);
      }
    });
  }

  getCommodityCategoryAtServer(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        // 先從檔案讀取, 若無, 則呼叫 api 取得 並儲存到檔案
        const filename = 'cache-GetCommodityCategoryViews.json';
        const filepath = `./${filename}`;

        console.log('@Server: getCommodityCategoryAtServer filepath', filepath);
        fs.readFile(filepath, 'utf8', async (err, data) => {
          if (err) {
            console.error('@Server: getCommodityCategoryAtServer error', err);
            reject(err);
          }

          if (data) {
            const data_obj = JSON.parse(data);
            console.log(
              '@Server: getCommodityCategoryAtServer got cache data, rows: ',
              data_obj?.length,
              '[' + new Date().toISOString() + ']'
            );
            resolve(data_obj);
          } else {
            console.log(
              '@Server: getCommodityCategoryAtServer got no cache, call api to get data'
            );
            const new_data = await this.getCommodityCategory();
            fs.writeFile(filepath, JSON.stringify(new_data), (err) => {
              if (err) {
                console.error(
                  '@Server: getCommodityCategoryAtServer error',
                  err
                );
                reject(err);
              }

              console.log(
                '@Server: getCommodityCategoryAtServer save cache success'
              );
              resolve(new_data);
            });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // 依照查詢參數, 列出商品
  getCommodityList(params: QueryCommodityParams): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityViewsByPaging`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.put(endpoint, params).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCommodityList error', error);
        reject(error);
      }
    });
  }

  // 依照查詢參數, 取得商品詳細資料
  getCommodityDetail(id: number): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityInfoView/${id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getCommodityDetail error', error);
        reject(error);
      }
    });
  }

  // 依照查詢參數, 取得隱形賣場商品詳細資料
  getSpecialCommodityDetail(id: number): Promise<any> {
    const endpoint = `Portal/Commodity/GetCommodityInfoViewBySpecial/${id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const result: APIResponse = await (<any>(
          this.api.get(endpoint).toPromise()
        ));
        console.log('result', result);
        resolve(result);
      } catch (error) {
        console.error('getSpecialCommodityDetail error', error);
        reject(error);
      }
    });
  }
}
